import { Box, TableCell, Typography, TableHead } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles"
import styled from "styled-components";

export const StyledTableCell = styled(TableCell)({
	borderBottom: "none !important",
});

export const StickyTableHead = MuiStyled(TableHead)({
  position: "sticky",
  top: 0,
  background: "#fff",
  zIndex: 1,
});

export const TableHeaderTypography = styled(Typography)((attr) => ({
	fontWeight: "600 !important",
	fontSize: "16px !important",
	color: `${attr.nestedHeader ? "#454545" : "#25262D"} !important`,
}));

export const TableCellTypography = styled(Typography)((attr) => ({
	fontWeight: "400 !important",
	fontSize: "16px !important",
	color: `${attr.color || attr.nested ? "#66696A" : "#454545"} !important`,
}));

export const TableWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	margin: "20px",
	borderRadius: "20px",
	padding: "15px",
	height: "100%",
	overflow: "hidden",
	boxShadow: "0px 0px 13px 0px #3A3A3A0F",
	border: "1px solid #EEF3F8",
});

export const TableHeaderWrapper = styled(Box)({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "16px",
});

export const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #EAEDF6;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
  width: 280px;
  height: 40px;
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  background: transparent;

  &::placeholder {
    color: #BCC0C2;
  }
`;

// Styled components for the Date Range Picker
export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding: 10px;
  border: 1px solid #EAEDF6;
  border-radius: 10px;
  background-color: #ffffff;
`;

export const DateRangeText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #25262D;
`;
