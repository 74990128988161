import React, { useEffect, useRef, useState, useMemo } from "react";
import { Box } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import AppLayout from "../../components/AppLayout/AppLayout";
import "./SRoles.css";
import { PANEL, getPanelType, madTechState } from "../../features/madTechSlice";
import SAddRole from "./SAddRole";
import {
	useAddRoleMutation,
	useAddSPermissionMutation,
} from "../../services/query";
import { useMyContext } from "../../components/CommanToasterContext/toast";
import { Strings } from "../../utils/Strings";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import Loader from "../../components/Setting/Loader";
import { useNavigate } from "react-router-dom";

const SRoleAddForm = () => {
	const panelType = useSelector(getPanelType);
	const { selectedAccount } = useSelector(madTechState);
	const navigate = useNavigate();
	const [
		addRole,
		{ data: addRoleData, isLoading: addRoleLoading, error: addRoleError },
	] = useAddRoleMutation();
	const [
		getData,
		{
			data: AddPermission,
			isLoading: getRoleLoading,
			error: getRoleError,
			refetch,
		},
	] = useAddSPermissionMutation();

	const { setOpen, setMessage, setOpen2, setMessage2, setOpen1, setMessage1 } =
		useMyContext();

	const [roleDetails, setRoleDetails] = useState({
		name: "",
		description: "",
		rolePermissionDetails: [
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Dashboard",
			},
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Connections",
			},
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Users",
			},
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Profile",
			},
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Roles",
			},
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Accounts",
			},
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Reports",
			},
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Contracts",
			},
			{
				permissions: {
					modify: false,
					view: false,
					create: false,
					delete: false,
				},
				name: "Invoices",
			},
		],
	});

	useEffect(() => {
		if (panelType === PANEL.PLATFORM_USER) {
			setRoleDetails({
				name: "",
				description: "",
				rolePermissionDetails: [
					{
						name: "Dashboard",
						permissions: {
							modify: false,
							view: false,
							create: false,
							delete: false,
						},
					},
					{
						name: "Connections",
						permissions: {
							modify: false,
							view: false,
							create: false,
							delete: false,
						},
					},
					{
						name: "Users",
						permissions: {
							modify: false,
							view: false,
							create: false,
							delete: false,
						},
					},
					{
						name: "Reports",
						permissions: {
							modify: false,
							view: false,
							create: false,
							delete: false,
						},
					},
					{
						name: "Platform Details",
						permissions: {
							modify: false,
							view: false,
							create: false,
							delete: false,
						},
					},
					{
						name: "Profile",
						permissions: {
							modify: false,
							view: false,
							create: false,
							delete: false,
						},
					},
					{
						name: "Roles",
						permissions: {
							modify: false,
							view: false,
							create: false,
							delete: false,
						},
					},
					{
						name: "User",
						permissions: {
							modify: false,
							view: false,
							create: false,
							delete: false,
						},
					},
				],
			});
		}
	}, [panelType]);

	const _handleAddRole = (data) => {
		const details = {
			rolePermissionDetails: roleDetails.rolePermissionDetails,
			name: data.roleName.trim(),
			description: data.description,
		};
		addRole(details);
	};

	useEffect(() => {
		// if (!roleId || selectedAccount === undefined) return;
		getData();
	}, []);

	useEffect(() => {
		if (
			AddPermission !== undefined &&
			AddPermission.statusCode === 200 &&
			AddPermission.response
		) {
			var response = AddPermission.response;
			setRoleDetails({
				name: "",
				description: "",
				rolePermissionDetails: response,
			});
		}

		if (getRoleError !== undefined) {
			console.log("error", getRoleError);
			setMessage2(getRoleError?.message);
			setOpen2(true);
		}
	}, [AddPermission, getRoleError]);

	useEffect(() => {
		if (addRoleData !== undefined) {
			if (addRoleData.statusCode === 200) {
				setMessage(addRoleData?.statusMessage);
				setOpen(true);
				// window.location.replace(selectedAccount ? '/roles?accountId=' + selectedAccount.id : '/roles')
				navigate(
					selectedAccount ? "/roles?accountId=" + selectedAccount.id : "/roles"
				);
			} else {
				setMessage2(addRoleData?.statusMessage);
				setOpen2(true);
			}
		}
		if (addRoleError !== undefined) {
			console.log("addRoleError", addRoleError);
		}
	}, [addRoleData, addRoleError]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<AppLayout>
			<Box sx={{ px: "1.5rem" }}>
				<SBreadCrumb
					mainPathName="Settings"
					mainPath="myprofile"
					pathName="Add New Role"
					ModulepathName="Roles"
					modulePath="roles"
					state={true}
				/>
				{getRoleLoading || roleDetails == null ? (
					getRoleError ? (
						<>Something went wrong</>
					) : (
						<Loader />
					)
				) : (
					<SAddRole
						key={"add"}
						roleDetails={roleDetails}
						setRoleDetails={setRoleDetails}
						_handleAddRole={_handleAddRole}
						addRoleLoading={addRoleLoading}
					></SAddRole>
				)}
			</Box>
		</AppLayout>
	);
};

export default SRoleAddForm;
