import React, { useCallback, useMemo, useState } from 'react'
import { Box, FormControl, FormHelperText } from "@mui/material";
import { Controller } from 'react-hook-form';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';

import './Plans.css'

const getRangeDate = (dateSpan) => {
    switch(dateSpan) {
        case "all":
            return {
                minDate: dayjs().subtract(100, 'year'),
                maxDate: dayjs().add(100, 'year'),
            };
        case "past":
            return {
                minDate: dayjs().subtract(100, 'year'),
                maxDate: dayjs(),
            };
        case "future":
        default:
            return {
                minDate: dayjs().add(1, 'day'),
                maxDate: dayjs().add(100, 'year'),
            };
    }
}

const CommanDatePicker = ({
    name,
    control,
    required,
    value,
    label,
    dateSpan
}) => {
    const [open, setOpen] = useState(false);
    const defaultEndTime = new Date();
    const disablePastandCurrentDate = useMemo(() => dateSpan && dateSpan === 'future', [dateSpan])

    defaultEndTime.setHours(23, 59, 0, 0);
  
    const controlDates = useCallback((date) => {
        if (disablePastandCurrentDate) {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set time to start of day
            return date <= today;
        }

        return false
    }, [disablePastandCurrentDate]);

    const handleDateTimeChange = useCallback((newValue, onChange) => {
        if (disablePastandCurrentDate) {
            const today = new Date();
            const isToday = 
            newValue.get("date") === today.getDate() &&
            newValue.get("month") === today.getMonth() &&
            newValue.get("year") === today.getFullYear();
            
            if (!isToday) {
                onChange(newValue)
                return;
            }

            const incrementedDate = new Date(newValue);
            incrementedDate.setDate(incrementedDate.getDate() + 1);
            onChange(dayjs(incrementedDate));
        } else {
            onChange(newValue);
        }
    }, [disablePastandCurrentDate])

    return (
        <Box sx={{
            marginTop: "15px",
            width: "100%",
            // overflow: 'visible !important'
        }}
            className='changeDate' >
            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ overflow: 'visible !important' }}>
                <DemoContainer components={['DateTimeRangePicker']} sx={{ overflow: 'visible !important' }}>
                    <Controller
                        name={name}
                        sx={{ overflow: 'visible !important' }}
                        control={control}
                        defaultValue={value ? dayjs(value) : null}
                        rules={required ? { required: "This field is required" } : {}}
                        render={({ field, fieldState: { error } }) => {
                            const { onChange, ...fieldProps } = field;
                          
                            return (
                                <FormControl error={!!error} fullWidth sx={{ overflow: 'visible !important' }}>
                                    <DateTimePicker
                                        shouldDisableDate={controlDates}
                                        disableHighlightToday={disablePastandCurrentDate}
                                        {...fieldProps}
                                        onChange={(newValue) => handleDateTimeChange(newValue, onChange)}
                                        label={`Initial Sync - ${label}`}
                                        className='dateranges'
                                        sx={{
                                            border: !!error ? "1px solid red" : "",
                                            borderRadius: "5px !important",
                                            "& input": {
                                                color: "#1C2B47", fontSize: "13px", fontFamily: "Helvetica Neue"
                                            },
                                            "& .MuiInputLabel-sizeMedium": {
                                                top: "0px !important",
                                            },

                                        }}
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        slotProps={{
                                            textField: {
                                                onClick: () => setOpen(true),

                                            },
                                        }}
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    )
}

export default CommanDatePicker