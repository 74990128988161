import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

import AppLayout from "../../components/AppLayout/AppLayout";
import { color } from "../../utils/Colors";
import {
  useDeleteRequestPlatformMutation,
  useDeleteSelectedPlatformMutation,
  useFetchPlatformsQuery,
  useGetSelectedPlatformsQuery,
} from "../../services/platformQuery";
import {
  madTechState,
  setIsMyplatforms,
  setPlatformId,
} from "../../features/madTechSlice";
import CommonButton from "../../components/CommonButton/CommonButton";
import connectionLogo from "../../assets/Connector.svg";
import AddIcon from "@mui/icons-material/Add";
import trash from "../../assets/trash-2.svg";
import cancel from "../../assets/cancel.svg";
import { useNavigate } from "react-router-dom";
import { favIconMapping } from "../../components/FavIconMaping/favicon";
import noConfigImg from "../../assets/Group 1000010554.svg";
import {
  BoxWrapper,
  CardDetailSubWrapper,
  CardDetailWrapper,
  ColumnHeading,
  ConfigureText,
  ConfigureWrapper,
  ConnectionsText,
  ConnectionsWrapper,
  ConnectionsWrapperNumbers,
  DataTypeSubWrapper,
  DataTypeWrapper,
  EnvWrapper,
  Heading,
  HeadingSubWrapper,
  HeadingWrapper,
  LoaderWrapper,
  NoDataHeading,
  NoDataWrapper,
  PlatformSubWrapper,
  PlatformText,
  PlatformWrapper,
  StatusText,
  StatusWrapper,
  SubWrapper,
  Wrapper,
} from "./PLatform.styles";
import snowFlakeIcon from "../../assets/snowflake.png"
import connectorImg1 from "../../assets/Connector.svg";
// import awsIcon from "../../assets/awsIcon.png"
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import SBreadCrumb from "../../components/Setting/SBreadCrumb";

const theme = createTheme({
  palette: {
    primary: {
      main: color.buttonColor,
    },
  },
});


const columnArr = [
  "Platform",
  "Data type",
  "Configuration",
  "Source/Destination",
  "#Connections",
  "Status",
  <CloudQueueIcon sx={{ width: "20px", height: "20px", margin: '0 auto 0 37px' }} />,
  "Action",
];
const ButtonConfi = false

const Platform = () => {
  const matchesxss = useMediaQuery("(min-width:600px)");
  const isSmallForTableHeader = useMediaQuery("(max-width:1390px)");
  const isMyPaltformForTableHeader = useMediaQuery("(max-width:975px)");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    platfomrs,
    allSelectedPlatforms,
    isMyplatforms,
    platformId,
    selectedAccount,
  } = useSelector(madTechState);

  const [searchValue, setSearchValue] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedPlatformIds, setSelectePlatformIds] = useState([]);

  const [allplatfomrs, setAllplatfomrs] = useState([]);
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);
  const [searchPlatforms, setSearchPlatforms] = useState([]);
  const [showDataAfterFilter, setShowDataAfterFilter] = useState([]);

  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [stateName, setStateName] = useState("My Platforms");

  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);

  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const [inputText, setInputText] = useState("");

  function getFavIconImagePath(key) {
    if (favIconMapping.hasOwnProperty(key)) {
      return favIconMapping[key];
    } else if (isDeleteModal) {
      return "platforms2/favicon.png";
    }
  }

  const { data, error } = useFetchPlatformsQuery();

  const {
    data: platformsData,
    isLoading: selectedPlatformLoading,
    error: selectedPlatformsError,
    refetch,
  } = useGetSelectedPlatformsQuery();

  const [deleteSelectedPlatform, { data: deletResponse, error: deleteError }] =
    useDeleteSelectedPlatformMutation();

  useEffect(() => {
    refetch();
  }, []);

  const _deleteSelectedPlatform = useCallback(
    (id, dataTypeId) => {
      deleteSelectedPlatform({ id, dataTypeId });
    },
    [useDeleteSelectedPlatformMutation]
  );

  useEffect(() => {
    if (searchValue === "") {
      setAllplatfomrs(platfomrs);
      setSelectedCategories([]);
      setCategoryName("");
    }
  }, [searchValue]);

  useEffect(() => {
    if (selectedPlatform?.length > 0) {
      setSelectePlatformIds(selectedPlatform?.map((item) => item.id));
    } else {
      setSelectePlatformIds([]);
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (selectedCategories?.length > 0 && platfomrs?.length > 0) {
      const newAllPlatforms = [];
      platfomrs?.forEach((ptItem) => {
        ptItem?.categories?.forEach((catEl) => {
          if (selectedCategories?.includes(catEl)) {
            if (!newAllPlatforms?.includes(catEl)) {
              newAllPlatforms?.push(ptItem);
            }
          }
        });
      });
      const filteredPlatforms = allplatfomrs?.filter((platform) => {
        return platform.categories.some((category) =>
          selectedCategories?.includes(category)
        );
      });
      setShowDataAfterFilter(filteredPlatforms);

      let newselectedPlatform = [...allSelectedPlatforms];
      let newArr = [];
      newselectedPlatform?.forEach((ptItem) => {
        ptItem?.categories?.forEach((catEl) => {
          if (selectedCategories?.includes(catEl)) {
            if (!newArr.includes(catEl)) {
              newArr.push(ptItem);
            }
          }
        });
      });
    } else {
      setAllplatfomrs(platfomrs);
    }
  }, [selectedCategories, platfomrs, allplatfomrs]);

  useEffect(() => {
    if (data !== undefined && data.response) {
      // console.log('data', data);
      setAllplatfomrs(data?.response?.platforms);
      setAllCategories(data?.response?.categories);
    }
    if (error !== undefined) {
      console.log("error", error);
      if (error.statusCode === 403) {
        navigate("signin");
      }
    }
  }, [data, error]);

  useEffect(() => {
    if (platformsData !== undefined && platformsData.response) {
      setSelectedPlatform(platformsData?.response?.selectedPlatforms);
    }

    if (selectedPlatformsError !== undefined) {
      console.log("error", selectedPlatformsError);
      if (selectedPlatformsError.statusCode === 403) {
        navigate("signin");
      }
    }
  }, [platformsData, selectedPlatformsError]);

  const [
    deleteRequestPlatform,
    {
      data: deleteRequestedPlatformData,
      isLoading: deleteRequestPlatformLoading,
      error: deleteRequestPlatformError,
    },
  ] = useDeleteRequestPlatformMutation();

  const _deleteRequestedPlatform = useCallback(
    (id, dataTypeId) => {
      var deleteRequestPlatformBody = {
        id,
        dataTypeId,
      };

      deleteRequestPlatform(deleteRequestPlatformBody);
    },
    [useDeleteRequestPlatformMutation]
  );

  useEffect(() => {
    if (deleteRequestedPlatformData !== undefined) {
      if (deleteRequestedPlatformData?.statusCode != 200) {
        // setRequestError(deleteRequestedPlatformData.statusMessage);
      } else {
        selectedPlatform?.length > 0 && setSelectedPlatform(
          selectedPlatform.filter(
            (el) => el.id !== deleteRequestedPlatformData?.response
          )
        );
      }
    }
    if (deleteRequestPlatformError !== undefined) {
      console.log("error", deleteRequestPlatformError);
    }
  }, [deleteRequestedPlatformData, deleteRequestPlatformError]);

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");
  const [isDeletePlatformModal, setIsDeletePlatformModal] = useState(false);

  useEffect(() => {
    if (deletResponse !== undefined) {
      setIsDeletePlatformModal(false);
      // console.log('data', deletResponse);
      if (deletResponse?.statusCode === 200) {
        refetch();
      } else {
        // show error popup
        setDeleteErrorMsg(deletResponse?.statusMessage);
        setIsDeleteModal(true);
      }
    }
    if (deleteError !== undefined) {
      console.log("error", deleteError);
    }
  }, [deletResponse, deleteError]);

  useEffect(() => {
    setInputText("");
  }, [isDeletePlatformModal]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
    setInputText(inputValue);
  };
  useEffect(() => {
    if (inputText === "DELETE") {
      setIsDeleteButtonEnabled(true);
    } else {
      setIsDeleteButtonEnabled(false);
    }
  }, [inputText]);

  useEffect(() => {
    if (filteredPlatforms?.length === 0) {
      const arr = allplatfomrs.filter((item) =>
        item.name.toLowerCase().startsWith(searchValue.toLowerCase())
      );
      setSearchPlatforms(arr);
    } else {
      const arr1 = showDataAfterFilter.filter((item) =>
        item.name.toLowerCase().startsWith(searchValue.toLowerCase())
      );
      setSearchPlatforms(arr1);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchPlatforms.length === 0) {
      if (filteredPlatforms?.length === 0) {
        setShowDataAfterFilter(allplatfomrs);
      } else {
        setShowDataAfterFilter(filteredPlatforms);
      }
    } else {
      setShowDataAfterFilter(searchPlatforms);
    }
  }, [filteredPlatforms, searchPlatforms, searchValue]);

  const handleAddClick = () => {
    navigate(
      selectedAccount
        ? "/platform/add?accountId=" + selectedAccount.id
        : "/platform/add"
    );
  };

  const iconClick = (item) => {
    setStateName(isMyplatforms);
    dispatch(setIsMyplatforms("Configuration"));
    dispatch(
      setPlatformId({
        id: item.id,
        logo: item.logo,
        name: item.name,
        docUrl: item.docUrl,
        overviewUrl: item.overviewUrl,
      })
    );
    window.scrollTo({ behavior: "auto", top: 0 });
  };

  const handleConfigureClick = (item) => {
    setStateName(isMyplatforms);
    navigate(
      selectedAccount
        ? `/platform/${item.id}?dataType=${item.dataType}&dataTypeId=${item.dataTypeId}&accountId=` +
        selectedAccount.id
        : `/platform/${item.id}?dataType=${item.dataType}&dataTypeId=${item.dataTypeId}`,
      { state: { platform: true, connectorId: item.connectorId } }
    );

    dispatch(
      setPlatformId({
        id: item.id,
        logo: item.logo,
        name: item.name,
        docUrl: item.docUrl,
        overviewUrl: item.overviewUrl,
      })
    );
    window.scrollTo({ behavior: "auto", top: 0 });
  };

  const handleDeleteModal = (item) => {
    if (item.logo !== undefined) {
      setIsDeletePlatformModal(true);
      setSelectedItem(item);
      // _deleteSelectedPlatform(item.id)
    } else {
      _deleteRequestedPlatform(item.id, item.dataTypeId);
    }
  };

  return (
    <AppLayout>
      <ThemeProvider theme={theme}>
        <Wrapper>
          {(!selectedPlatformLoading && selectedPlatform !== null) ? (
            <SubWrapper>
              <Box sx={{
                position: "sticky",
                top: "0",
                paddingTop: "8px",
                background: "#FAFCFF",
                width: "100%",
                zIndex: 99,
                boxShadow: "0 -10px 0 #FAFCFF",
              }}>
                <HeadingSubWrapper matchesxss={matchesxss}>
                  <SBreadCrumb mainPathName="My Platforms" mainPath="platform" />
                  {selectedPlatform?.length !== 0 && (
                    <div>
                      <CommonButton
                        width={"11.375rem"}
                        height={"2.5rem"}
                        textStyle={{
                          fontSize: "1rem",
                          fontWeight: 600,
                          letterSpacing: "normal",
                        }}
                        onClick={handleAddClick}
                        title={"Add Platform"}
                        color={color.buttonColor}
                        startIcon={
                          <AddIcon
                            startIconStyle={{ height: 24, width: 24 }}
                            sx={{ marginRight: "-8px", marginLeft: "4px" }}
                          />
                        }
                      ></CommonButton>
                    </div>
                  )}
                </HeadingSubWrapper>
                <Box
                  sx={{
                    display: selectedPlatform?.length !== 0 ? "flex" : "none",
                    flexDirection: matchesxss ? "row" : "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    backgroundColor: "#FAFCFF",
                    mt: "1.5rem",
                    gap: "8px",
                    px: "8px",
                  }}
                >
                  {columnArr?.map((colName, i) => {
                    return (
                      <ColumnHeading
                        key={i}
                        colName={colName}
                        isSmallForTableHeader={isSmallForTableHeader}
                        isMyPaltformForTableHeader={
                          isMyPaltformForTableHeader
                        }
                      >
                        {colName}
                      </ColumnHeading>
                    );
                  })}
                </Box>
              </Box>
              {selectedPlatform?.length !== 0 ? (
                <CardDetailWrapper className="cls-for-scroll">
                  {selectedPlatform?.map(
                    (item, idx) =>
                      item?.logo && (
                        <CardDetailSubWrapper
                          key={idx}
                          isMyPaltformForTableHeader={
                            isMyPaltformForTableHeader
                          }
                        >
                          <PlatformWrapper>
                            <PlatformSubWrapper
                              onClick={() => iconClick(item)}
                            >
                              <img
                                alt=""
                                src={
                                  item.logo
                                    ? getFavIconImagePath(item.logo)
                                    : connectionLogo
                                }
                                style={{
                                  maxWidth: "100%",
                                  height: "50px",
                                  cursor: "default",
                                  objectFit: "contain",
                                  minWidth: "2.5rem",
                                  maxWidth: "2.5rem",
                                  height: "2.5rem",
                                  borderRadius: 2,
                                  p: 1,
                                }}
                              />
                              <Tooltip
                                title={
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: isMyPaltformForTableHeader
                                        ? "12px"
                                        : "1rem",
                                    }}
                                  >
                                    {item.name ? item.name : ""}
                                  </span>
                                }
                                placeholder="top"
                              >
                                <PlatformText
                                  isMyPaltformForTableHeader={
                                    isMyPaltformForTableHeader
                                  }
                                >
                                  {item.name ? item.name : ""}
                                </PlatformText>
                              </Tooltip>
                            </PlatformSubWrapper>
                          </PlatformWrapper>
                          <DataTypeWrapper>
                            <DataTypeSubWrapper>
                              <Tooltip
                                title={
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: isMyPaltformForTableHeader
                                        ? "12px"
                                        : "1rem",
                                    }}
                                  >
                                    {item.dataType ? item.dataType : ""}
                                  </span>
                                }
                                placeholder="top"
                              >
                                <PlatformText
                                  isMyPaltformForTableHeader={
                                    isMyPaltformForTableHeader
                                  }
                                >
                                  {item.dataType ? item.dataType : ""}
                                </PlatformText>
                              </Tooltip>
                            </DataTypeSubWrapper>
                          </DataTypeWrapper>
                          <ConfigureWrapper>

                            <ConfigureText
                              isMyPaltformForTableHeader={
                                isMyPaltformForTableHeader
                              }
                              // onClick={( item?.env === selectedAccount?.env?.name) ?
                              onClick={item?.editable ?
                                () => handleConfigureClick(item)
                                : null}
                              sx={{
                                color: item?.editable ? color.buttonColor : 'rgba(0, 0, 0, 0.38)',
                                cursor: item?.editable && "pointer"
                              }}
                            >

                              {/* {item.name} */}
                              Configure
                            </ConfigureText>
                          </ConfigureWrapper>

                          <ConnectionsWrapper>
                            <ConnectionsText
                              isMyPaltformForTableHeader={
                                isMyPaltformForTableHeader
                              }
                            >
                               {item?.supportedType?.toLowerCase() === "DESTINATION".toLocaleLowerCase() ? `Destination`: `Source`}
                            </ConnectionsText>
                          </ConnectionsWrapper>

                          <ConnectionsWrapperNumbers>
                            <ConnectionsText
                              isMyPaltformForTableHeader={
                                isMyPaltformForTableHeader
                              }
                            >
                                {item.srcConnections ? `${(item.srcConnections)}` : item.destConnections ? `${(item.destConnections)}` : "0"}
                            </ConnectionsText>
                          </ConnectionsWrapperNumbers>

                          <StatusWrapper>
                            <StatusText
                              isMyPaltformForTableHeader={
                                isMyPaltformForTableHeader
                              }
                            >
                              {item.configured
                                ? "Configured"
                                : "Unconfigured"}
                            </StatusText>
                          </StatusWrapper>

                          {/* env column */}
                          <EnvWrapper width="7%">
                            {/* {
                                item?.configured ?  */}
                            <Tooltip title={item?.env === "madconnect" ? "MadConnect" : "Snowflake"}>
                              <img
                                alt="not found"
                                src={item?.env === "madconnect" ? connectorImg1 : snowFlakeIcon}
                                style={{ width: 24, height: 24, color: "#193241" }}
                              />
                            </Tooltip>
                            {/* : "---"
                              } */}

                          </EnvWrapper>

                          <StatusWrapper width="7%">
                            <Box
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                                textAlign:
                                  isMyPaltformForTableHeader && "center",
                              }}
                              onClick={() => handleDeleteModal(item)}
                            >
                              <img
                                src={trash}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  width: isMyPaltformForTableHeader
                                    ? "16px"
                                    : "",
                                }}
                              />
                            </Box>
                          </StatusWrapper>

                        </CardDetailSubWrapper>
                      )
                  )}
                </CardDetailWrapper>
              ) : (
                <NoDataWrapper>
                  <Box>
                    <img
                      src={noConfigImg}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Box>
                  <NoDataHeading>No platforms selected!</NoDataHeading>
                  <Button
                    variant="outlined"
                    onClick={handleAddClick}
                    sx={{
                      border: "1px solid black",
                      color: "#fff",
                      textTransform: "capitalize",
                      background: "#000",
                      borderRadius: "8px",
                      mt: 3,
                      "&:hover": {
                        background: "#000",
                        border: "1px solid black",
                        cursor: "pointer",
                      },
                    }}
                  >
                    Add Platform
                  </Button>
                </NoDataWrapper>
              )}
            </SubWrapper>
          ) : null}
        </Wrapper>
        {selectedPlatformLoading || selectedPlatform === null ? (
          <LoaderWrapper>
            <CircularProgress variant="indeterminate" sx={{ color: color.loadingColor }} disableShrink size={45} thickness={8} />
          </LoaderWrapper>
        ) : null}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isDeleteModal}
          onClose={() => setIsDeleteModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isDeleteModal}>
            <BoxWrapper sx={{ height: "15.5rem" }}>
              {selectedItem && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        p: "0.5rem",
                        width: "100%",
                        height: "3.75rem",
                        borderRadius: 1,
                        //   border: "1px solid rgba(54, 70, 172, 0.15)",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "2.5rem",
                          borderRadius: 1,
                          // backgroundColor: "rgba(54, 70, 172, 0.07)",
                          gap: "10px",
                          p: 0.4,
                        }}
                      >
                        <img
                          alt=""
                          src={getFavIconImagePath(selectedItem?.logo)}
                          // src='https://devk0fhuj1.madconnect.io/logos/favicons/snapchat.svg'
                          // style={{ width: "60px", height: "70px" }}
                          style={{ height: "2.7rem" }}
                        ></img>
                        <Typography sx={{ fontSize: 22, fontFamily: "Helvetica Neue", color: "#000000C2", }}> {selectedItem?.name}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Typography
                style={{
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "1rem",
                  textAlign: "center",
                  color: "#656C7B",
                  width: "80%",
                }}
              >
                {deleteErrorMsg}
                {/* Cannot delete the platform as it is being used in a connection */}
              </Typography>
              <Box sx={{ mt: 3 }}>
                <CommonButton
                  width={"4.375rem"}
                  height={"2.25rem"}
                  textStyle={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    fontFamily: "Helvetica Neue",
                  }}
                  onClick={() => setIsDeleteModal(false)}
                  title={"Okay"}
                  color={color.buttonColor}
                ></CommonButton>
              </Box>
            </BoxWrapper>
          </Fade>
        </Modal>

        {/* Delete Platform modal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isDeletePlatformModal}
          onClose={() => {
            setIsDeletePlatformModal(false);
            setSelectedItem([]);
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isDeletePlatformModal}>
            <BoxWrapper sx={{ width: "31.25rem" }}>
              {selectedItem && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        p: "0.5rem",
                        width: "100%",
                        height: "3.75rem",
                        borderRadius: 1,
                        //   border: "1px solid rgba(54, 70, 172, 0.15)",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "2.5rem",
                          borderRadius: 1,
                          gap: "10px",
                          p: 0.4,
                        }}
                      >
                        <img
                          alt=""
                          src={getFavIconImagePath(selectedItem?.logo)}
                          style={{ height: "2.7rem" }}
                        ></img>
                        <Typography sx={{ fontSize: 22, fontFamily: "Helvetica Neue", color: "#000000C2", }}>  {selectedItem.name}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Typography
                style={{
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "1rem",
                  textAlign: "center",
                  color: "#656C7B",
                  marginBottom: "1rem",
                  // width: '70%'
                }}
              >
                Are you sure you want to remove this platform?
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <FormControl
                  sx={{
                    width: "70%",
                    height: "2.75rem",
                    fontSize: "1rem",
                    color: "#666666",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Helvetica Neue",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  size="small"
                >
                  <InputLabel
                    sx={{
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      opacity: 0.5,
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Helvetica Neue",
                    }}
                    id="delete"
                  >
                    Type DELETE to confirm
                  </InputLabel>
                  <OutlinedInput
                    placeholder={""}
                    sx={{
                      width: "100%",
                      height: "2.75rem",
                      fontSize: "1rem",
                      color: "#666666",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Helvetica Neue",
                    }}
                    labelid="delete"
                    id="delete"
                    label="Type DELETE to confirm"
                    onChange={handleInputChange}
                    value={inputText}
                    autoComplete="off"
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  mt: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Button
                  onClick={() => {
                    _deleteSelectedPlatform(
                      selectedItem?.id,
                      selectedItem?.dataTypeId
                    );
                    setSelectedItem(selectedItem);
                  }}
                  style={{
                    cursor: "pointer",
                    // backgroundColor: color.whiteColor,
                    backgroundColor: isDeleteButtonEnabled
                      ? color.whiteColor
                      : "#CCCCCC",
                    width: "7rem",
                    height: "2.5rem",
                    color: isDeleteButtonEnabled ? color.themeRed : "#fff",
                    textTransform: "none",
                    border: "1.5px solid",
                    borderColor: isDeleteButtonEnabled
                      ? color.themeRed
                      : "transparent",
                  }}
                  disabled={!isDeleteButtonEnabled}
                  variant="contained"
                >
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <img
                      alt=""
                      src={trash}
                      style={{
                        width: "1.3rem",
                        display: "flex",
                        alignItems: "center",
                        filter: !isDeleteButtonEnabled && "grayscale(100%)",
                      }}
                    ></img>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        fontFamily: "Helvetica",
                        display: "flex",
                        alignItems: "center",
                        // marginTop: 2,
                      }}
                    >
                      Delete
                    </Typography>
                  </Box>
                </Button>
                <Button
                  onClick={() => {
                    setIsDeletePlatformModal(false);
                    setSelectedItem([]);
                  }}
                  style={{
                    cursor: "pointer",
                    // border: `1px solid ${color.themeDarkGray}`,
                    backgroundColor: color.whiteColor,
                    width: "6.625rem",
                    height: "2.5rem",
                    color: color.themeBlack,
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <img
                      alt=""
                      src={cancel}
                      style={{
                        width: "0.8rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></img>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        fontFamily: "Helvetica",
                        display: "flex",
                        alignItems: "center",
                        // marginTop: 2,
                      }}
                    >
                      Cancel
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </BoxWrapper>
          </Fade>
        </Modal>

        {/* Configuration Madal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isConfigurationOpen}
          // onClose={() => setIsConfigurationOpen(false)}
          onClose={() => {
            setIsConfigurationOpen(false);
            setSelectedItem([]);
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isConfigurationOpen}>
            <BoxWrapper sx={{ height: "12.5rem" }}>
              <Typography
                style={{
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "1rem",
                  textAlign: "center",
                  color: "#656C7B",
                  width: "70%",
                }}
              >
                You need to configure your account with {selectedItem?.name} to
                use in the connection. Do you wish to configure it now?
              </Typography>
              <Box
                sx={{
                  width: "40%",
                  mt: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => setIsConfigurationOpen(false)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: color.buttonColor,
                    width: "auto",
                    height: "2.5rem",
                    marginRight: "0.625rem",
                    color: color.whiteColor,
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    Skip
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    setStateName(isMyplatforms);
                    dispatch(setIsMyplatforms("Configuration"));
                    dispatch(
                      setPlatformId({
                        id: platformId.id,
                        logo: platformId.logo,
                        name: platformId.name,
                        docUrl: platformId.docUrl,
                        overviewUrl: platformId.overviewUrl,
                      })
                    );
                    setIsConfigurationOpen(false);
                    window.scrollTo({ behavior: "auto", top: 0 });
                  }}
                  style={{
                    cursor: "pointer",
                    border: `1px solid ${color.buttonColor}`,
                    backgroundColor: color.whiteColor,
                    width: "auto",
                    height: "2.5rem",
                    color: color.buttonColor,
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    Configure
                  </Typography>
                </Button>
              </Box>
            </BoxWrapper>
          </Fade>
        </Modal>
      </ThemeProvider>
    </AppLayout>
  );
};

export default Platform;
