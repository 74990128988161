import React from "react";
import { CircularProgress } from "@mui/material";

import { LoaderWrapper } from "./Setting.styles";
import { color } from "../../utils/Colors";

const Loader = () => {
  return (
    <LoaderWrapper>
      <CircularProgress variant="indeterminate" disableShrink size={45} thickness={8} sx={{ color: color.loadingColor }} />
    </LoaderWrapper>
  );
};

export default Loader;
