import React, { useEffect } from "react";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  Button,
  CircularProgress,
} from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { color } from "../../../utils/Colors";
import CommonButton from "../../../components/CommonButton/CommonButton";
import { Strings } from "../../../utils/Strings";
import { madTechState } from "../../../features/madTechSlice";
import trash from "../../../assets/trash-2.svg";
import {
  AddUserFieldWrapper,
  AddUserFromHeading,
  AddUserFromWrapper,
  InputFieldWrapper,
  InputLabelWrapper,
  InputsWrapper,
  PhoneInputWrapper,
} from "../SUers.styles";
import { leftArr, rightArr } from "../constants";

const SEditUser = ({
  roles,
  _handleUpdateUser,
  isUserEdit,
  setIsDeleteOpen,
  userDetails,
  setUserDetails,
  updateSuserLoading
}) => {
  const navigate = useNavigate();
  const { selectedAccount } = useSelector(madTechState);

  // Initialize React Hook Form
  const { register, handleSubmit, formState: { errors } , control, watch } = useForm({
    defaultValues: userDetails
  });



  const onSubmit = (data) => {
    // Handle form submission
    const updatedData = {
      ...data,
      phoneNumber: userDetails.phoneNumber
  };
    setUserDetails(updatedData)
    _handleUpdateUser(updatedData);
  };

  const handleCancel = () => {
    navigate(
      selectedAccount
        ? "/users?accountId=" + selectedAccount.id
        : "/users"
    );
  }

  return (
    <>
      {/* USER DETAILS START */}
      <AddUserFromWrapper>
        <AddUserFromHeading>User details</AddUserFromHeading>
        <form onSubmit={handleSubmit(onSubmit)}> {/* Wrap form elements in a form tag */}
          <AddUserFieldWrapper>
            <Box display="flex" flexDirection="column" width="44%">
              <Box display="flex" justifyContent="space-between">
                {leftArr?.map((data, i) => (
                  <InputsWrapper size="small" key={i}>
                    <InputLabelWrapper id={data.id}>{data.label}</InputLabelWrapper>
                    <InputFieldWrapper
                      placeholder={data.label}
                      labelid={data.id}
                      id={data.id}
                      label={data.label}
                      {...register(data.id, { required: true })} // Register input with validation
                      error={!!errors[data.id]}
                      disabled={!isUserEdit}
                    />
                  </InputsWrapper>
                ))}
              </Box>
              <InputsWrapper width="100%" mt="24px" size="small">
                <InputLabelWrapper id="workEmail">Work email*</InputLabelWrapper>
                <InputFieldWrapper
                  placeholder={"Work email*"}
                  labelid="workEmail"
                  id="workEmail"
                  label="Work email*"
                  {...register("email", { required: true })} // Register input with validation
                  error={!!errors.email}
                  disabled={!isUserEdit}
                />
              </InputsWrapper>
              <Box sx={{ mt: 3, height: "2.75rem", backgroundColor: "#FFFFFF" }}>
                <PhoneInputWrapper
                  placeholder={`${Strings.phoneNumber}*`}
                  inputClass="colors"
                  country={"us"}
                  countryCodeEditable={false}
                  enableSearch={true}
                  value={userDetails.phoneNumber}
                  onChange={(phone) => {
                    setUserDetails({ ...userDetails, phoneNumber: phone });
                  }}
                  disabled={!isUserEdit}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "44%",
              }}
            >
              {rightArr?.map((data, i) => (
                <InputsWrapper key={i} width="100%" mt={i > 0 && "24px"} size="small">
                  <InputLabelWrapper id={data.id}>{data.label}</InputLabelWrapper>
                  <InputFieldWrapper
                    placeholder={data.label}
                    labelid={data.id}
                    id={data.id}
                    label={data.label}
                    {...register(data.id, { required: data.required })} // Register input with validation
                    error={!!errors[data.id]}
                    disabled={!isUserEdit}
                  />
                </InputsWrapper>
              ))}
              <InputsWrapper width="100%" mt="24px" size="small">
              <InputLabelWrapper id="role">Role*</InputLabelWrapper>
              <Controller
                name="roleId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    sx={{
                      height: "2.75rem",
                      fontSize: "1rem",
                      color: "#666666",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Helvetica Neue",
                    }}
                    labelid="role"
                    id="roleId"
                    label="Role*"
                  {...register("roleId", { required: true })} // Register input with validation

                    error={!!errors.roleId}
                    disabled={!isUserEdit}
                  >
                    {roles?.map((role) => (
                      <MenuItem
                        key={role.id}
                        sx={{
                          fontSize: "0.875rem",
                          backgroundColor: "#FFFFFF",
                          fontFamily: "Helvetica Neue",
                        }}
                        value={role.id}
                      >
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </InputsWrapper>
            </Box>
          </AddUserFieldWrapper>

          {/* BUTTONS START */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              my: "1rem",
              gap: "0.5rem",
            }}
          >
            <CommonButton
              width={106}
              height={40}
              textStyle={{
                fontSize: "1rem",
                fontWeight: 600,
                fontFamily: "Helvetica",
                letterSpacing: "normal",
              }}
              customStyle={{
                marginRight: "20px",
                marginTop: "20px",
                cursor: !isUserEdit ? "not-allowed" : "",
              }}
              type="submit" // Set button type to submit
              title={"Save"}
              color={color.buttonColor}
              disabled={!isUserEdit || updateSuserLoading}
              startIcon={
                !updateSuserLoading ?
                  <BookmarkBorderOutlinedIcon
                    startIconStyle={{ height: 24, width: 24 }}
                    sx={{ marginRight: "-8px", marginLeft: "4px" }}
                  /> :
                  <CircularProgress variant="indeterminate" disableShrink sx={{ color: color.loadingColor }} size={18} thickness={5}></CircularProgress>
              }
            ></CommonButton>

            <Button
              onClick={handleCancel}
              className="cancel_style1"
              variant="contained"
              disabled={updateSuserLoading}
              startIcon={
                <ClearOutlinedIcon
                  startIconStyle={{ height: 24, width: 24 }}
                  sx={{ marginRight: "-8px", marginLeft: "4px" }}
                />
              }
            >
              <label
                style={{
                  cursor: "pointer",
                  fontSize: "1rem",
                  fontWeight: 600,
                  fontFamily: "Helvetica",
                  letterSpacing: "normal",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1px",
                }}
              >
                Cancel
              </label>
            </Button>
            <Button
              onClick={() => {
                setIsDeleteOpen(true);
              }}
              style={{
                cursor: "pointer",
                backgroundColor: color.whiteColor,
                width: "106px",
                height: "40px",
                color: color.themeRed,
                textTransform: "none",
                border: "1.5px solid",
                borderColor: color.themeRed,
              }}
              variant="contained"
            >
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <img
                  alt=""
                  src={trash}
                  style={{
                    width: "1.3rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></img>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: 600,
                    letterSpacing: "normal",
                    display: "flex",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  Delete
                </Typography>
              </Box>
            </Button>
          </Box>
          {/* BUTTONS END */}
        </form>
      </AddUserFromWrapper>
    </>
  );
};

export default SEditUser;