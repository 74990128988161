import React from "react";
import {
  Box,
  MenuItem,
  Select,
  Button,
  CircularProgress,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useNavigate } from "react-router-dom";

import { color } from "../../../utils/Colors";
import CommonButton from "../../../components/CommonButton/CommonButton";
import { Strings } from "../../../utils/Strings";
import {
  AddUserFieldWrapper,
  AddUserFromHeading,
  AddUserFromWrapper,
  InputFieldWrapper,
  InputLabelWrapper,
  InputsWrapper,
  PhoneInputWrapper,
} from "../SUers.styles";
import { leftArr, rightArr } from "../constants";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const SAddUser = ({ userDetails, setUserDetails, _handleAddUser, roles, addUSerLoading }) => {
  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: userDetails,
  });
  const queryParams = new URLSearchParams(window.location.search);

  const accountId = queryParams.get("accountId");
  const onSubmit = (data) => {
    _handleAddUser(data);
  };

  const handleCancel = () => {
    navigate(accountId ? "/users?accountId=" + accountId : "/users");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AddUserFromWrapper>
        <AddUserFromHeading>User details</AddUserFromHeading>
        <AddUserFieldWrapper>
          <Box display="flex" flexDirection="column" width="44%">
            <Box display="flex" justifyContent="space-between">
              {leftArr?.map((data, i) => (
                <InputsWrapper size="small" key={i}>
                  <InputLabelWrapper id={data.id}>{data.label}</InputLabelWrapper>
                  <Controller
                    name={data.id}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      console.log(field)
                      return (
                        <InputFieldWrapper
                          {...field}
                          placeholder={data.label}
                          labelid={data.id}
                          id={data.id}
                          label={data.label}
                          error={!!errors[data.id]}

                        />

                      )
                    }}
                  />
                </InputsWrapper>
              ))}
            </Box>
            <InputsWrapper width="100%" mt="24px" size="small">
              <InputLabelWrapper id="workEmail">Work email*</InputLabelWrapper>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputFieldWrapper
                    {...field}
                    placeholder="Work email*"
                    labelid="workEmail"
                    id="workEmail"
                    label="Work email*"
                    error={!!errors.email}
                  />
                )}
              />
            </InputsWrapper>
            <Box sx={{ mt: 3, height: "2.75rem", backgroundColor: "#FFFFFF" }}>
              <Controller
                name="phoneNumber"
                control={control}
                // rules={{
                //   required: true,
                //   maxLength: 10,
                // }}
                render={({ field }) => (
                  <PhoneInputWrapper
                    {...field}
                    placeholder={`${Strings.phoneNumber}*`}
                    inputClass="colors"
                    country={"us"}
                    countryCodeEditable={false}
                    enableSearch={true}
                    error={!!errors.phoneNumber}

                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "44%",
            }}
          >
            {rightArr?.map((data, i) => (
              <InputsWrapper key={i} width="100%" mt={i > 0 && "24px"} size="small">
                <InputLabelWrapper id={data.id}>{data.label}</InputLabelWrapper>
                <Controller
                  name={data.id}
                  control={control}
                  rules={{ required: data.required }}
                  render={({ field }) => (
                    <InputFieldWrapper
                      {...field}
                      placeholder={data.label}
                      labelid={data.id}
                      id={data.id}
                      label={data.label}
                      error={!!errors[data.id]}

                    />
                  )}
                />
              </InputsWrapper>
            ))}

            <InputsWrapper width="100%" mt="24px" size="small">
              <InputLabelWrapper id="role">Role*</InputLabelWrapper>
              <Controller
                name="roleId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    sx={{
                      height: "2.75rem",
                      fontSize: "1rem",
                      color: "#666666",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Helvetica Neue",
                    }}
                    labelid="role"
                    id="roleId"
                    label="Role*"
                    error={!!errors.roleId}
                  >
                    {roles?.map((role) => (
                      <MenuItem
                        key={role.id}
                        sx={{
                          fontSize: "0.875rem",
                          backgroundColor: "#FFFFFF",
                          fontFamily: "Helvetica Neue",
                        }}
                        value={role.id}
                      >
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </InputsWrapper>
          </Box>
        </AddUserFieldWrapper>
      </AddUserFromWrapper>
      {/* USER DETAILS END */}

      {/* BUTTONS START */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          my: "1rem",
          gap: "0.5rem",
        }}
      >
        <CommonButton
          width={106}
          height={40}
          textStyle={{
            fontSize: "1rem",
            fontWeight: 600,
            fontFamily: "Helvetica",
            letterSpacing: "normal",
          }}
          disabled={addUSerLoading}
          customStyle={{
            marginRight: "20px",
            marginTop: "20px",
          }}
          type="submit"
          title={"Save"}
          color={color.buttonColor}
          startIcon={
            !addUSerLoading ?
              <BookmarkBorderOutlinedIcon
                startIconStyle={{ height: 24, width: 24 }}
                sx={{ marginRight: "-8px", marginLeft: "4px" }}
              /> :
              <CircularProgress variant="indeterminate" disableShrink sx={{ color: color.loadingColor }} size={18} thickness={5}></CircularProgress>
          }
        ></CommonButton>
        <Button
          onClick={handleCancel}
          className="cancel_style1"
          variant="contained"
          disabled={addUSerLoading}
          startIcon={
            <ClearOutlinedIcon
              startIconStyle={{ height: 24, width: 24 }}
              sx={{ marginRight: "-8px", marginLeft: "4px" }}
            />
          }
        >
          <label
            style={{
              cursor: "pointer",
              fontSize: "1rem",
              fontWeight: 600,
              fontFamily: "Helvetica",
              letterSpacing: "normal",
              display: "flex",
              alignItems: "center",
              marginTop: "1px",
            }}
          >
            Cancel
          </label>
        </Button>
      </Box>
      {/* BUTTONS END */}
    </form>
  );
};

export default SAddUser;