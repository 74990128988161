import { Box, Button, Tab, TableCell, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../../../utils/Colors";

export const Wrapper = styled(Box)({
  maxWidth: "100%",
  margin: "auto",
  backgroundColor: color.whiteColor,
});

export const ConnectionContainer = styled(Box)({
  backgroundColor: color.whiteColor,
  marginTop: "16px",
  padding: "8px 16px 0px",
  borderRadius: "8px",
  marginBottom: "1rem",
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
});

export const LoaderWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

export const Conatiner = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  margin: "0px auto 2.5rem",
  height: "calc(100vh - 116px - 3vh)",
  justifyContent:"center"
});

export const RightContainer = styled(Box)({
  color: "#454545",
  fontSize: "1rem",
  fontWeight: 500,
  fontFamily: "Helvetica Neue",
});

export const RightContainerHeader = styled(Box)({ 
  marginBottom: "1.2rem", 
  color: "#000", 
  display: "flex", 
  width: "100%", 
  justifyContent: "space-between", 
  alignItems: "center" 
});

export const RightContainerHeaderTitle = styled(Typography)({
  fontFamily: "Helvetica Neue !important",
  fontSize: "1rem",
  fontWeight: 500, 
})

export const Heading = styled(Typography)({
  color: "#25262D !important",
  fontSize: "0.875rem !important",
  fontWeight: "500 !important",
  marginTop: "24px !important",
  fontFamily: "Helvetica Neue !important",
});

export const TableCellStyle = styled(TableCell)({
  border: "none !important",
  color: "#454545 !important",
  fontSize: "1rem !important",
  padding: "16px 16px 16px 10px !important",
  display: (props) => props.hidden && "none",
  justifyContent: (props) => props.center && "center !important",
  textAlign: (props) => props.headCenter && "center !important"
});

export const TabHeading = styled("span")({
  fontSize: "16px",
  fontFamily: "Helvetica Neue",
  padding: "1px",
  marginTop: "2px",
  textTransform: "capitalize",
});

export const TabHeadingCount = styled("span")({
  fontSize: "16px",
  fontFamily: "Helvetica Neue",
  border: (props) =>
    props.same
      ? "1px solid rgba(83, 139, 244, 1)"
      : "1px solid rgba(97, 97, 97, 1)",
  background: (props) =>
    props.same ? "rgba(83, 139, 244, 0.05)" : "rgba(97, 97, 97, 0.05)",
  borderRadius: "20px",
  padding: "1px 7px",
  paddingTop: "2.5px",
});

export const TabStyled = styled(Tab)({
  fontSize: "16px !important",
  fontFamily: "Helvetica Neue !important",
  padding: "14px 25px !important",
  borderRight: (props) =>
    props.condition ? "1px solid rgba(234, 237, 246, 1) !important" : "none",
  textTransform: "capitalize",
});

export const ConnectionListRightHeading = styled(Box)({
  color: "#25262D !important",
  fontSize: "1rem !important",
  fontWeight: "500 !important",
  fontFamily: "Helvetica Neue !important",
});

export const ConnectionListRightTestBtn = styled(Button)({
  textTransform: "capitalize !important",
  borderRadius: "10px !important",
  background: (props) => !props.isDisable && "#538BF4 !important",
  minWidth: "110px !important",
  minHeight: "42px !important",
  marginTop: (props) => props.loading && "0px !important",
});

export const ConnectionListRightTestingtHeading = styled(Typography)({
  color: "#25262D !important",
  fontSize: "0.875rem !important",
  fontWeight: "500 !important",
  fontFamily: "Helvetica Neue !important",
  marginTop: "16px !important",
  // width: "90% !important",
});

export const ConnectionListRightActivateBtn = styled(Button)({
  textTransform: "capitalize !important",
  borderRadius: "10px !important",
  minWidth: "110px !important",
  minHeight: "42px !important",
});