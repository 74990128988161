import { useMemo, useEffect } from "react";

const useFilterConnectors = ({ allCategories, selectedCategories, allPlatforms, setPlatforms }) => {
    const categoryMapping = useMemo(() => {
        const mapping = {};
        allCategories.forEach(category => {
            category.subCategories.forEach(subCat => {
                mapping[subCat.id] = {
                    name: subCat.name,
                    categoryType: category.name
                };
            });
        });
        return mapping;
    }, [allCategories]);

    const getCategoryIds = (categoryName) => {
        const category = allCategories.find(category => category.name === categoryName);
        if (!category) return [];
        return category.subCategories.map(scItem => scItem.id);
    };

    const integrationTypeFilters = useMemo(() => getCategoryIds("Integration Type"), [allCategories]);
    const functionalityFilters = useMemo(() => getCategoryIds("Functionality"), [allCategories]);
    const platformTypeFilters = useMemo(() => getCategoryIds("Platform Type"), [allCategories]);

    useEffect(() => {
        if (selectedCategories?.length > 0 && allPlatforms?.length > 0) {
            let newPlatforms = [...allPlatforms];

            let integrationTypeCategories = selectedCategories.filter(id => integrationTypeFilters.includes(id));
            let platformTypeCategories = selectedCategories.filter(id => platformTypeFilters.includes(id));
            let functionalityCategories = selectedCategories.filter(id => functionalityFilters.includes(id));

            if (integrationTypeCategories.length > 0) {
                const integrationTypeNames = integrationTypeCategories.map(id => 
                    categoryMapping[id].name.toLowerCase()
                );
                newPlatforms = newPlatforms.filter(platform => 
                    integrationTypeNames.includes(platform?.supportedType?.toLowerCase())
                );
            }

            if (platformTypeCategories.length > 0) {
                const platformTypeNames = platformTypeCategories.map(id => 
                    categoryMapping[id].name.toLowerCase()
                );
                newPlatforms = newPlatforms.filter(platform => 
                    platformTypeNames.includes(platform?.platformType?.toLowerCase())
                );
            }

            if (functionalityCategories.length > 0) {
                const functionalityNames = functionalityCategories.map(id => 
                    categoryMapping[id].name.toLowerCase()
                );
                newPlatforms = newPlatforms.filter(platform => 
                    functionalityNames.includes(platform?.dataTypeCategory?.toLowerCase())
                );
            }

            setPlatforms(newPlatforms);
        } else {
            setPlatforms(allPlatforms);
        }
    }, [selectedCategories, allPlatforms, categoryMapping]);
};

export default useFilterConnectors;