import React from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import 'react-lazy-load-image-component/src/effects/blur.css';

import homeBackImg from '../../assets/HomeBackImg2.webp'
import madConnectLogo from '../../assets/madconnectlight 2 (1).svg'
import { setImageLoaded, setIsPlatformContact, madTechState } from '../../features/madTechSlice';
import { BannerContainer, BannerHeading, BannerLetConnectBtn, BannerSubHeading, BannerSuperContainer, LoadingComponent } from './Home.styles';
import { color } from '../../utils/Colors';


const Banner = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { imageLoaded } = useSelector(madTechState)

    const matcheslg = useMediaQuery('(min-width:1100px)');
    const matchesmd = useMediaQuery('(min-width:980px)');
    const matchessm = useMediaQuery('(min-width:880px)');
    const matchesxs = useMediaQuery('(min-width:760px)');
    const matchesxss = useMediaQuery('(min-width:520px)');

    return (
        <Box position="relative" width='100%'>
            <Box height={ matcheslg ? 615 : matchesmd ? 550 : matchessm ? 500 : matchesxs ? 450 : 400 }>
                <LazyLoadImage
                    alt={'MADCONNECT'}
                    width={'100%'}
                    height={'100%'}
                    src={homeBackImg}
                    onLoadCapture={() => dispatch(setImageLoaded(true))} />
            </Box>
            {!imageLoaded ?
                <LoadingComponent height={matcheslg ? 615 : matchesmd ? 550 : matchessm ? 500 : matchesxs ? 450 : 400}>
                    <CircularProgress variant="indeterminate" disableShrink sx={{ color: color.loadingColor }} size={40} thickness={4} />
                </LoadingComponent>
                :
                <BannerSuperContainer >
                    <BannerContainer matchesxss={matchesxss}>
                        <img style={{ cursor: 'pointer', width: 184, height: 'auto', marginBottom: 10 }} alt={'MADCONNECT LOGO 2'} src={madConnectLogo}></img>

                        <Box width={matchesxss ? '55%' : '80%' }>
                            <BannerHeading  fontSize={matcheslg ? '3.5rem' : matchesmd ? '3rem' : matchessm ? '2.5rem' : matchesxs ? '2rem' : '1.6rem'}>
                                ONE APP FOR ALL CONNECTORS
                            </BannerHeading>
                        </Box>
                        <Box  width={ matchesxss ? '70%' : '90%' }>
                            <BannerSubHeading  fontSize={matcheslg ? '1.5rem' : matchesmd ? '1.2rem' : matchessm ? '1.2rem' : matchesxs ? '1rem' : '1rem'}>
                                MadConnect is now a Snowflake Native App!
                            </BannerSubHeading>
                            </Box>
                        <BannerLetConnectBtn
                        width={matchesmd ? '15%' : matchesxs ? '30%' : '40%'}
                        target="_blank"
                        href="https://madconnect-and-snowflake.tiiny.co/"
                        >
                            <Typography 
                                color= '#fff'
                                fontSize={matcheslg ? '1.2rem' : matchesmd ? '1rem' : matchessm ? '1rem' : matchesxs ? '1rem' : '1rem'}>
                                    Learn More
                                    </Typography>
                        </BannerLetConnectBtn>
                        {/* <BannerLetConnectBtn
                        width={matchesmd ? '15%' : matchesxs ? '30%' : '40%'}
                        onClick={() => {
                            dispatch(setIsPlatformContact(false))
                            navigate('/contact')
                        }}
                        >
                            <Typography 
                                color= '#fff'
                                fontSize={matcheslg ? '1.2rem' : matchesmd ? '1rem' : matchessm ? '1rem' : matchesxs ? '1rem' : '1rem'}>
                                    Let’s Connect
                                    </Typography>
                        </BannerLetConnectBtn> */}
                    </BannerContainer>
                </BannerSuperContainer>
            }
        </Box>
    )
}

export default Banner