import {
  Backdrop,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Button,
  Fade,
  Modal,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import connectorImg1 from "../../../../assets/Connector.svg";
import lineImg from "../../../../assets/Line_18.svg";
import cancel from "../../../../assets/cancel.svg";
import trash from "../../../../assets/trash-2.svg";
import { color } from "../../../../utils/Colors";
import { useDeleteConnectionMutation, useUpdateConnectionsStatusMutation } from "../../../../services/connectionsQuery";
import { useMyContext } from "../../../../components/CommanToasterContext/toast";
import { BoxDeleteToConfirm, BoxImg, BoxImgContainer, BoxImgInner, BoxMiddelImg, ButtonBox, ButtonName, ButtonWrapper, DivMiddelImg, FormControlDeleteToConfirm, ImgStyled, WrapperTypography, WrapperTypographyImage, WrapperTypographyPara } from "../Styles/ConnectionList.styles";


const DeleteModal = ({
  open,
  handleClose,
  selectedItem,
  isForDisable,
  setSelectedItem,
  refetch,
  setIsDeleteSuccess,
  setActiveTab
}) => {
  const style = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px 8px 8px 8px",
      bgcolor: "#F8F8FE",
      width: "31.25rem",
      boxShadow: 24,
      p: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "&:focus": {
        outline: "none",
      },
    }),
    []
  );

  const { setOpen2, setMessage2 ,setMessage , setOpen } = useMyContext();

  const [
    deleteConnection,
    {
      data: deleteConnectionData,
      isLoading: deleteConnectionLoading,
      error: deleteConnectionError,
    },
  ] = useDeleteConnectionMutation();

  const [
    updateConnectionStatus,
    {
      data: updateConnectionStatusData,
      isLoading: updateConnectionStatusLoading,
      error: updateConnectionStatusError,
    },
  ] = useUpdateConnectionsStatusMutation();
   
  console.log(updateConnectionStatusData,"updateConnectionStatusData")
  const [inputText, setInputText] = useState("");
  
  useEffect(() => {    
    if (deleteConnectionData !== undefined) {
      if (deleteConnectionData?.statusCode === 200) {
        // refetch();
        handleClose();
        setSelectedItem(null);
        setIsDeleteSuccess(true);
      }
    }

    if (deleteConnectionError !== undefined) {
      setMessage2(deleteConnectionError?.data?.error);
      setOpen2(true);
    }
  }, [deleteConnectionData, deleteConnectionError]);


  useEffect(() => {
    if (updateConnectionStatusData !== undefined) {
      if(updateConnectionStatusData?.statusCode == 200) {
        setMessage(updateConnectionStatusData?.statusMessage)
        setOpen(true);
        refetch();
        handleClose();
        setSelectedItem(null);
        if(!selectedItem.enabled){
          setActiveTab("Active")
        }
      }else {
        setMessage2(updateConnectionStatusData?.statusMessage)
        setOpen2(true);
      }
    }

    if (updateConnectionStatusError !== undefined) {
      console.log("updateConnectionStatusError", updateConnectionStatusError);
    }
  }, [updateConnectionStatusData, updateConnectionStatusError]);
  const deleteConnector = () => {
    deleteConnection({
      id: selectedItem?.conId,
    });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
    setInputText(inputValue);
  };

  const IsDisable = useCallback(() => {
    if (inputText === "DELETE" || isForDisable) {
      return false;
    }
    return true;
  }, [inputText]);

  const _handleUpdateConnectionStatus = useCallback(() => {
    updateConnectionStatus({
      id: selectedItem?.conId,
      enabled: !selectedItem.enabled,
    });
  }, [useUpdateConnectionsStatusMutation, selectedItem]);

  return (
    <>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...style, pl: 5, pr: 5, width: 600 }}>
          <WrapperTypographyImage>
            <BoxImg>
              <BoxImgContainer>
                <BoxImgInner>
                  <ImgStyled>
                    <img
                      alt=""
                      src={selectedItem?.src.logo}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    ></img>
                  </ImgStyled>
                </BoxImgInner>
                <DivMiddelImg/>
                <BoxMiddelImg>
                  <img
                    alt=""
                    src={connectorImg1}
                    style={{ width: 24, height: 24, color: "#193241" }}
                  ></img>
                </BoxMiddelImg>
                <img
                  alt=""
                  src={lineImg}
                  style={{ width: 55, marginRight: 5, marginLeft: 5 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    p: 1,
                    width: "35%",
                    height: 60,
                    borderRadius: 1,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "2.5rem",
                      borderRadius: 1,
                      p: 0.4,
                    }}
                  >
                    <img
                      alt=""
                      src={selectedItem?.dest.logo}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    ></img>
                  </Box>
                </Box>
              </BoxImgContainer>
            </BoxImg>
          </WrapperTypographyImage>

          <WrapperTypographyPara>
            Are you sure you want{" "}
            {isForDisable
              ? `${!selectedItem?.enabled ? "enable" : "disable"}`
              : `delete`}{" "}
            this connection?
          </WrapperTypographyPara>

          {!isForDisable && (
            <BoxDeleteToConfirm>
              <FormControlDeleteToConfirm
                size="small"
              >
                <InputLabel
                  sx={{
                    fontSize: "0.875rem",
                    marginTop: "0.25rem",
                    opacity: 0.5,
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Helvetica Neue",
                  }}
                  id="delete"
                >
                  Type DELETE to confirm
                </InputLabel>
                <OutlinedInput
                  placeholder={""}
                  sx={{
                    width: "100%",
                    height: "2.75rem",
                    fontSize: "1rem",
                    color: "#666666",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Helvetica Neue",
                  }}
                  labelid="delete"
                  id="delete"
                  label="Type DELETE to confirm"
                  onChange={handleInputChange}
                  value={inputText}
                  autoComplete="off"
                />
              </FormControlDeleteToConfirm>
            </BoxDeleteToConfirm>
          )}
          
          <ButtonWrapper>
            <Button
              onClick={(event) => {
                if (isForDisable) {
                    _handleUpdateConnectionStatus(event);
                } else {
                  deleteConnector();
                }
              }}
              disabled={IsDisable()}
              style={{
                backgroundColor: !IsDisable()
                  ? isForDisable
                    ? color.buttonColor
                    : color.whiteColor
                  : "#CCCCCC",
                color: !IsDisable()
                  ? isForDisable
                    ? color.whiteColor
                    : color.themeRed
                  : "#FFFFFF",
                width: "6.625rem",
                height: "2.5rem",
                textTransform: "none",
                border: "1.5px solid",
                borderColor: !IsDisable()
                  ? isForDisable
                    ? color.buttonColor
                    : color.themeRed
                  : "transparent",
                cursor: !IsDisable() ? "pointer" : "not-allowed",
              }}
              variant="contained"
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                {!isForDisable ? (
                  !deleteConnectionLoading ?
                    <img
                      alt="trash"
                      src={trash}
                      style={{
                        width: "1.3rem",
                        display: "flex",
                        alignItems: "center",
                        filter: IsDisable() ? "grayscale(100%)" : "none",
                      }}
                    ></img>
                    :
                    <CircularProgress variant="indeterminate" disableShrink style={{ color: color.loadingColor }} size={20} thickness={6}></CircularProgress>
                ) : (
                  updateConnectionStatusLoading && <CircularProgress variant="indeterminate" disableShrink style={{ color: color.loadingColor }} size={20} thickness={6}></CircularProgress>
                )
                }

                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    fontFamily: "Helvetica",
                    display: "flex",
                    alignItems: "center",
                    // marginTop: 2,
                  }}
                >
                  {isForDisable ? "Yes" : "Delete"}
                </Typography>
              </Box>
            </Button>
            <Button
              onClick={handleClose}
              isLoading={deleteConnectionLoading}
              disabled={deleteConnectionLoading}
              style={{
                cursor: "pointer",
                // border: `1px solid ${color.themeDarkGray}`,
                backgroundColor: color.whiteColor,
                width: "6.625rem",
                height: "2.5rem",
                color: color.themeBlack,
                textTransform: "none",
              }}
              variant="contained"
            >
              <ButtonBox>
                {!isForDisable && (
                  <img
                    alt=""
                    src={cancel}
                    style={{
                      width: "0.8rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></img>
                )}
                <ButtonName>
                  {isForDisable ? "No" : "Cancel"}
                </ButtonName>
              </ButtonBox>
            </Button>
          </ButtonWrapper>
        </Box>
      </Fade>
    </Modal>
    </>
  );
};

export default DeleteModal;
