import dayjs from "dayjs";
import { env } from "./config.js";

export const formatTimestamp = (timestamp) => {
    const date = new Date(new Date(0).setUTCSeconds(timestamp));
    const formattedDate = dayjs(date).format("M/D/YYYY h:mmA");

    return formattedDate;
};

export const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

export const formatRows = (num) => {
    if (num >= 1e12) {
        return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
        return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else {
        // Use Intl.NumberFormat for adding commas
        return new Intl.NumberFormat("en-US").format(num);
    }
};

export const formatWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const convertUnixTimestampToAgo = (timestamp) => {
    const now = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
    const secondsAgo = now - timestamp;

    if (secondsAgo < 60) {
        return `${secondsAgo}s ago`;
    } else if (secondsAgo < 3600) {
        const minutesAgo = Math.floor(secondsAgo / 60);
        return `${minutesAgo}m ago`;
    } else if (secondsAgo < 86400) {
        const hoursAgo = Math.floor(secondsAgo / 3600);
        return `${hoursAgo}h ago`;
    } else {
        // Calculate the full date in "DD/MM/YYYY" format
        const date = new Date(timestamp * 1000);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }
};


export const getFontSize = (matches ,size) =>
    matches.lg
      ? size.lg
      : matches.md
      ? size.md
      : matches.sm
      ? size.sm
      : matches.xs
      ? size.xs
      : size.xss;

export const isSnowFlake = () => { 
    return env == "snowflake";
}
export const addVirtualCategories = (platforms, categories)=> {
    const _categories = [...categories];
    const allIds = categories.flatMap(cat => [cat.id, ...cat.subCategories.map(sCat => sCat.id)]); 
    const lastId = allIds.reduce((maxId, id) => Math.max(maxId, id), 0); 
    const type = {
        "id": lastId + 1,
        "name": "Type"
    }
    const source = {
        "id": lastId + 2,
        "name": "Source"
    };
    const destination = {
        "id": lastId + 3,
        "name": "Destination"
    }
    _categories.push({
        ...type ,     
        "subCategories": [
            source,
            destination
        ]
    });
    const _platforms = [];
    for(let platform of platforms) {
        const categories = [...platform.categories];
        if(platform.supportedType == "DESTINATION") {
            categories.push(destination.id);
        }
        if(platform.supportedType == "SOURCE") {
            categories.push(source.id);
        }
        if(platform.supportedType == "BOTH") {
            categories.push(source.id);
            categories.push(destination.id);
            categories.push(type.id);
        }
        _platforms.push({
            ...platform,
            categories
        })
    }
    return{ 
        platforms: _platforms, 
        categories: _categories 
    }
}