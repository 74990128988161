import { useSelector } from "react-redux";
import React from 'react';
import { madTechState } from "../../features/madTechSlice";
import { HeaderWrapper } from './Invoice.styles';
import SBreadCrumb from '../../components/Setting/SBreadCrumb';

const Header = () => {
    const { invoices, selectedAccount } = useSelector(madTechState);
    return (
        <>
            <HeaderWrapper>  
                <SBreadCrumb mainPathName="Settings" mainPath="myprofile" pathName="Invoice" />
            </HeaderWrapper>
        </>
    );
}

export default Header;
