import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  Modal,
  OutlinedInput,
  Paper,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";


import AppLayout from "../../components/AppLayout/AppLayout";
import { color } from "../../utils/Colors";
import searchImg from "../../assets/search.svg";
import {
  useDeleteRequestPlatformMutation,
  useDeleteSelectedPlatformMutation,
  useFetchPlatformsQuery,
  useGetSelectedPlatformsQuery,
  useRequestPlatformMutation,
  useSelectPlatformMutation,
} from "../../services/platformQuery";
import {
  madTechState,
  setIsMyplatforms,
  setPlatformId,
} from "../../features/madTechSlice";
import CommonButton from "../../components/CommonButton/CommonButton";
import slash from "../../assets/slash.svg";
import connectionLogo from "../../assets/Connector.svg";
import leftArrow from "../../assets/left_arrow.svg";
import infoLogo from "../../assets/info.svg";
import negativeLogo from "../../assets/Negative.svg";
import positiveLogo from "../../assets/Positive.svg";
import { Strings } from "../../utils/Strings";
import CommonInput from "../../components/CommonInput/CommonInput";
import trash from "../../assets/trash-2.svg";
import cancel from "../../assets/cancel.svg";
import { favIconMapping } from "../../components/FavIconMaping/favicon";
import { useMyContext } from "../../components/CommanToasterContext/toast";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import { ArrowForwardIconComp, GapSpan, SettingText, SPathName } from "../../components/Setting/Setting.styles";
import useFilterConnectors from "../../hook/useFilterConnectors";

// import blackLogo from '../../Assets/madtechdark.svg'
// import blackLogo from '../../Assets/madconnect-black.svg'
// import madConnectLogo from '../../Assets/madconnectdark.png'

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: color.buttonColor,
    },
  },
});

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: "48%",
  // backgroundColor: alpha(theme.palette.common.white, 0.15),
  // '&:hover': {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  // },
  // [theme.breakpoints.up('sm')]: {
  //     marginLeft: theme.spacing(1),
  //     width: 'auto',
  // },
}));


export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    border: `1px solid ${color.themeGray}`,
    borderRadius: 5,
    // transition: theme.transitions.create('width'),
    // [theme.breakpoints.up('sm')]: {
    //     width: '110%',
    //     '&:focus': {
    //         width: 440,
    //     },
    // },
  },
}));



export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(54, 70, 172, 0.02)",
  border: "1px solid rgba(54, 70, 172, 0.25)",
  borderRadius: 10,
  position: "relative",
  cursor: "default",
  height: 104,
  // aspectRatio: 3 | 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  "&:hover": { boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.1)" },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#193241",
    color: "#fff",
    maxWidth: 220,
    fontSize: "0.75rem",
  },
}));

const Platform = () => {
  const navigate = useNavigate();
  const matchesxss = useMediaQuery("(min-width:600px)");
  const matchexl = useMediaQuery("(min-width:1650px)");
  const dispatch = useDispatch();
  const {
    platfomrs,
    allSelectedPlatforms,
    isMyplatforms,
    platformId,
    selectedAccount,
  } = useSelector(madTechState);
  const [searchValue, setSearchValue] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [selectedPlatformIds, setSelectePlatformIds] = useState([]);
  const [searchedPlatforms, setSearchedPlatforms] = useState([]);
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);
  const [sucess, setSucess] = useState({})
  const [open, setOpen1] = useState(false);
  // console.log('selectedPlatform', selectedPlatform)
  const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();

  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [validateRequestPlatformForm, setValidateRequestPlatformForm] =
    useState(false);
  const [isRequestedPlaform, setIsRequestedPlatform] = useState(false);
  const [requestError, setRequestError] = useState("");
  const [stateName, setStateName] = useState("My Platforms");
  const [hoverItemId, setHoverItemId] = useState(null);
  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false)
  const [proceedIndex, setProceedIndex] = useState(0)
  const [hasMore, setHasMore] = useState(true);

  const isSmallScreen = useMediaQuery('(max-width:1099px)');
  const isSmallForTableHeader = useMediaQuery('(max-width:1390px)');
  const isMyPaltformForTableHeader = useMediaQuery('(max-width:1105px)');
  const isFilterHieght = useMediaQuery('(min-Height:900px)');
  const isCardHieght = useMediaQuery('(max-height:800px)');
  const isCOntentHieght = useMediaQuery('(max-height:739px)');
  const isContentHeight760 = useMediaQuery('(max-height:760px)');
  const isContentHeight920 = useMediaQuery('(max-height:920px)');
  
  useFilterConnectors({ allCategories, selectedCategories, allPlatforms: searchedPlatforms, setPlatforms: setFilteredPlatforms });

  function getFavIconImagePath(key) {
    if (favIconMapping.hasOwnProperty(key)) {
      return favIconMapping[key];
    } else {

      return "platforms2/favicon.png";
    }
  }

  const size = useMemo(() => {
    return 100
    // return isContentHeight760 ? 24 : isContentHeight920 ? 32 : 32
  }, [isContentHeight760, isContentHeight920])


  const style = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px 8px 8px 8px",
      bgcolor: "#F8F8FE",
      // width: "27.625rem",
      boxShadow: 24,
      p: 4,
      // height: "17.875rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // justifyContent: 'ceneter', alignItems: 'center'
    }),
    []
  );

  const { data, isLoading, error } = useFetchPlatformsQuery();
  const {
    data: platformsData,
    isLoading: selectedPlatformLoading,
    error: selectedPlatformsError,
    refetch,
  } = useGetSelectedPlatformsQuery();



  const [
    requestPlatform,
    {
      data: requestedPlatformData,
      isLoading: requestPlatformLoading,
      error: requestPlatformError,
    },
  ] = useRequestPlatformMutation();
  const [
    selectPlatform,
    {
      data: selectedPlatformData,
      isLoading: selectPlatformLoading,
      error: selecPlatformError,
    },
  ] = useSelectPlatformMutation();
  const [
    deleteSelectedPlatform,
    { data: deletResponse, isLoading: deleteLoading, error: deleteError },
  ] = useDeleteSelectedPlatformMutation();


  const fetchMoreData = () => {
    setLoading(true)
    setTimeout(() => {
      setProceedIndex((prevIndex) => prevIndex + size);
      setLoading(false)
    }, 1000)
  };

  const handleContainerScroll = (e) => {
    // Check if the inner container is scrolled to the bottom
    const container = e.target;
    const calVal = Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight)
    if (calVal <= 5 && !loading) {
      fetchMoreData();
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  const _deleteSelectedPlatform = useCallback(
    (id, dataTypeId) => {
      deleteSelectedPlatform({ id, dataTypeId });
    },
    [useDeleteSelectedPlatformMutation]
  );

  const _validateRequestPlatformForm = useCallback(() => {
    if (name.trim().length === 0 && url.trim().length === 0) {
      setValidateRequestPlatformForm(true);
      return true;
    } else {
      setValidateRequestPlatformForm(false);
    }
    return false;
  }, [name, url]);

  const _requestNewPlatform = useCallback(
    (event) => {
      event.preventDefault();
      // validate form
      if (_validateRequestPlatformForm()) {
        return;
      }

      var requestPlatformBody = {
        name,
        url,
      };

      requestPlatform(requestPlatformBody);
    },
    [useRequestPlatformMutation, name, url, _validateRequestPlatformForm]
  );

  useEffect(() => {
    if (searchValue === "") {
      setSearchedPlatforms(platfomrs);
    }
  }, [searchValue]);
  useEffect(() => {
    if (selectedPlatform?.length > 0) {
      setSelectePlatformIds(selectedPlatform?.map((item) => ({ id: item?.id, dataType: item?.dataType, connectorId: item?.connectorId })));
    } else {
      setSelectePlatformIds([]);
    }
  }, [selectedPlatform]);

  // old filtering logic
  // useEffect(() => {
  //   if (selectedCategories?.length > 0 && allplatfomrs?.length > 0) {
  //     const newAllPlatforms = [];
  //     allplatfomrs?.forEach((ptItem) => {
  //       ptItem?.categories?.forEach((catEl) => {
  //         if (selectedCategories?.includes(catEl)) {
  //           if (!newAllPlatforms?.includes(catEl)) {
  //             newAllPlatforms?.push(ptItem);
  //           }
  //         }
  //       });
  //     });
  //     const newSetArr = [...new Set(newAllPlatforms.map((data) => data))];
  //     // need to change this when filter is needed for all platforms
  //     // setAllplatfomrs(newSetArr)
  //     const filteredPlatforms = allplatfomrs?.filter((platform) => {
  //       return platform?.categories?.some((category) => selectedCategories?.includes(category));
  //     });
  //     setShowDataAfterFilter(filteredPlatforms);
  //     let newselectedPlatform = [...allSelectedPlatforms];
  //     let newArr = [];
  //     newselectedPlatform?.forEach((ptItem) => {
  //       ptItem?.categories?.forEach((catEl) => {
  //         if (selectedCategories?.includes(catEl)) {
  //           if (!newArr.includes(catEl)) {
  //             newArr.push(ptItem);
  //           }
  //         }
  //       });
  //     });
  //     const newSetArr2 = [...new Set(newArr?.map((data) => data))];
  //     //  if(isMyplatforms === "My Platform") {
  //     //   setSelectedPlatform(newSetArr2);
  //     //  }
  //   } else {
  //     setAllplatfomrs(allplatfomrs);
  //   }
  // }, [selectedCategory, platfomrs, allplatfomrs]);

  const handleSelectPlatform = useCallback(
    (id, dataTypeId, dataType) => {
      // const filteredIds = selectedPlatformIds.filter(item => item?.length > 3)
      selectPlatform({ platformIds: [{ platformId: id, dataTypeId: dataTypeId, dataType: dataType }] });
      // selectPlatform({ 'platformIds': filteredIds })
    },
    [useSelectPlatformMutation, selectedPlatformIds]
  );

  useEffect(() => {
    if (data?.response) {
      const platforms = data.response.platforms || [];
      const allCategories = data?.response?.categories || [];
      setSearchedPlatforms(platforms);
      setAllCategories(allCategories);

    } else if (data === undefined && error) {
      setSearchedPlatforms([]);
    } 
  }, [data, error]); 
 
  // useEffect(() => {
  //   if (data !== undefined && data.response) {
  //     const maxId = Math.max(
  //       ...data.response.categories.map((cat) => cat.id),
  //       ...data.response.platforms.flatMap((plat) => plat.categories || []),
  //       0
  //     );

  //     const dynamicStaticCategories = [
  //       { id: maxId + 1, name: "Type" },
  //       { id: maxId + 2, name: "Source" },
  //       { id: maxId + 3, name: "Destination" },
  //       { id: maxId + 4, name: "Both" }
  //     ];
  //     const allCategories = [...data?.response?.categories, ...staticCategories];
  //     setAllCategories(allCategories);
  //     const updatedPlatforms = data?.response?.platforms.map((item) => {
  //       let newCategories = [...(item.categories || [])];
  //       if (item.supportedType === "SOURCE" && !newCategories.includes(maxId + 2)) {
  //         newCategories.push(maxId + 2);
  //       } else if (item.supportedType === "DESTINATION" && !newCategories.includes(maxId + 3)) {
  //         newCategories.push(maxId + 3);
  //       } else if (item.supportedType === "BOTH" && !newCategories.includes(maxId + 4)) {
  //         newCategories.push(maxId + 4);
  //       }
  //       return {
  //         ...item,
  //         categories: newCategories,
  //       };
  //     });
  //     setAllplatfomrs(updatedPlatforms);
  //   }
  //   if (error !== undefined) {
  //     console.log("error", error);
  //     if (error.statusCode === 403) {
  //       navigate("signin");
  //     }
  //   }
  // }, [data, error]);

  // useEffect(() => {
  //   const maxId = lastPlatformId.length > 0 ? Math.max(...lastPlatformId) : 0;
  //   let newId = maxId + 1;
  //   staticCategories[0].id = newId++;
  //   staticCategories[0].subCategories = staticCategories[0].subCategories.map(subCategory => ({
  //     ...subCategory,
  //     id: newId++,
  //   }));
  // }, [lastPlatformId])

  useEffect(() => {
    if (
      selectedCategories.length === 0 &&
      allSelectedPlatforms
    ) {
      setSelectedPlatform(allSelectedPlatforms);
    }
  }, [selectedCategories, allSelectedPlatforms]);

  useEffect(() => {
    if (platformsData !== undefined && platformsData?.response) {
      // console.log('data', data);
      // setSelectedPlatform(platformsData.response.selectedPlatforms);
      setSelectedPlatform([...platformsData?.response?.selectedPlatforms, ...platformsData?.response?.requestedPlatforms?.map((ele) => ({ ...ele, logo: undefined }))])
    }

    if (selectedPlatformsError !== undefined) {
      if (selectedPlatformsError?.statusCode === 403) {
        navigate("signin");
      }
    }
  }, [platformsData, selectedPlatformsError]);
  // console.log('selectedPlatform', selectedPlatform)

  useEffect(() => {
    if (requestedPlatformData !== undefined) {
      if (requestedPlatformData?.statusCode != 200) {
        setRequestError(requestedPlatformData?.statusMessage);
      } else {
        setName("");
        setUrl("");
        setRequestError("");
        var item = requestedPlatformData?.response;

        if (selectedPlatform?.some(obj => obj.id === item?.id && obj.dataType !== item?.dataType)) {
          setSelectedPlatform([...selectedPlatform, item]);
        }
        setIsRequestedPlatform(false);
        setOpen1(true)
        setSucess(item)
      }
    }
    if (requestPlatformError !== undefined) {
      console.log("error", requestPlatformError);
    }
  }, [requestedPlatformData, requestPlatformError]);


  const handleClose = (event, reason) => {
    setOpen1(false);
  };

  const [
    deleteRequestPlatform,
    {
      data: deleteRequestedPlatformData,
      isLoading: deleteRequestPlatformLoading,
      error: deleteRequestPlatformError,
    },
  ] = useDeleteRequestPlatformMutation();

  const _deleteRequestedPlatform = useCallback(
    (id) => {
      var deleteRequestPlatformBody = {
        id,
      };

      deleteRequestPlatform(deleteRequestPlatformBody);
    },
    [useDeleteRequestPlatformMutation]
  );

  useEffect(() => {
    if (deleteRequestedPlatformData !== undefined) {
      if (deleteRequestedPlatformData?.statusCode != 200) {
        // setRequestError(deleteRequestedPlatformData.statusMessage);
      } else {
        setSelectedPlatform(
          selectedPlatform.filter(
            (el) => el.id !== deleteRequestedPlatformData?.response
          )
        );
        setIsDeletePlatformModal(false)
      }
    }
    if (deleteRequestPlatformError !== undefined) {
      console.log("error", deleteRequestPlatformError);
    }
  }, [deleteRequestedPlatformData, deleteRequestPlatformError]);

  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isDeletePlatformModal, setIsDeletePlatformModal] = useState(false);

  const logoUrl = selectedItem?.logo?.startsWith("http")
    ? selectedItem?.logo
    : `${selectedItem?.logo}`;

  useEffect(() => {
    if (deletResponse !== undefined) {
      setIsDeletePlatformModal(false);
      if (deletResponse?.statusCode === 200) {
        refetch()
        setOpen(true)
        setMessage(deletResponse?.statusMessage);
        return
        // setSelectedPlatform(
        //   selectedPlatform.filter(
        //     (el) => el.id !== selectedItem?.id
        //   )
        // );
      } else {
        // show error popup
        setOpen2(true)
        setMessage2(deletResponse?.statusMessage);
        // setDeleteErrorMsg(deletResponse.statusMessage);
        // setIsDeleteModal(true);
      }
    }
    if (deleteError !== undefined) {
      console.log("error", deleteError);
    }
  }, [deletResponse, deleteError]);

  useEffect(() => {
    setInputText("");
  }, [isDeletePlatformModal]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
    setInputText(inputValue);
  };
  useEffect(() => {
    if (inputText === "DELETE") {
      setIsDeleteButtonEnabled(true);
    } else {
      setIsDeleteButtonEnabled(false);
    }
  }, [inputText]);


  useEffect(() => {
    const arr = platfomrs?.filter((item) =>
      item?.name
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    )
    setSearchedPlatforms(arr)
  }, [searchValue, platfomrs])

  const SelectedPlatform = useMemo(() => {
    // return (isRequestedPlaform ? platformsData?.response?.requestedPlatforms : platformsData?.response?.selectedPlatforms)
    return isRequestedPlaform ? selectedPlatform?.filter((ele) => ele.logo === undefined) : selectedPlatform?.filter((ele) => ele.logo !== undefined)
  }, [isRequestedPlaform, selectedPlatform])

  const handleSettingClick = () => {
    navigate(
      selectedAccount ? `/platform?accountId=` + selectedAccount.id : `/platform`
    );
  };

  return (
    <AppLayout>
      <ThemeProvider theme={theme} >
        <Box sx={{ margin: "0px 24px", height: "40px", marginTop: "5px" }} display="flex">
          <SBreadCrumb mainPathName="My Platforms" mainPath="platform" pathName="Add Platform" />
        </Box>
        {/* <Box sx={{}}>
          <span
            style={{
              color:
                isMyplatforms !== "My Platform"
                  ? "#666666"
                  : color.themeColor,
              fontSize: "0.875rem",
              fontFamily: "Helvetica Neue",
              fontWeight: 500,
              cursor: "pointer",
              marginLeft: 15,
              marginTop: "14px"

            }}
            onClick={() => {
              navigate(selectedAccount
                ? "/platform?accountId=" + selectedAccount.id
                : "/platform")
            }}
          >
            My Platforms
          </span>
          <span
            style={{
              color:
                isMyplatforms !== "My Platform"
                  ? "#666666"
                  : color.themeColor,
              fontSize: "0.875rem",
              fontFamily: "Helvetica Neue",
              fontWeight: 500,
              marginLeft: 5,
            }}>{" / Add Platform"}</span>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: color.layoutBgColor,
            // height: "77vh",
            mt: 1,
            gap: "16px",
            flex: "1 1 0%"

          }}
        >

          {!isRequestedPlaform ?
            <Box
              sx={{
                // width: "12.5rem",
                pt: "1rem",
                borderRadius: 1,
                border: `1px solid ${color.themeLightGray}`,
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                minWidth: "200px"
              }}
            >
              <Box
                sx={{
                  px: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",

                }}
              >
                <Typography
                  className="heading"
                  sx={{
                    fontSize: "1.125rem",
                    color: "#8B989F",
                    fontWeight: 500,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  Filter by
                </Typography>
                {/* <Typography className='heading'
                                  sx={{ fontSize: "1rem", color: color.themeColor, fontWeight: 500, cursor: 'pointer', fontFamily: 'Helvetica Neue' }}
                                  onClick={() => {
                                      setSelectedCategories([])
                                  }}>Reset filters</Typography> */}
              </Box>
              <Box sx={{ px: 2 }}>
                <div
                  style={{
                    height: 1,
                    border: "1px solid #eeeeee",
                    marginTop: 10,
                    marginBottom: 5,
                  }}
                />
              </Box>
              <Box
                sx={{
                  py: 1,
                  px: 2,
                  mb: "1rem",
                  height: "2rem",
                  backgroundColor: "rgba(54, 70, 172, 0.05)",

                }}
              >
                <Typography
                  className="heading"
                  sx={{
                    fontSize: "0.875rem",
                    mb: 1,
                    color: "#3646AC",
                    cursor: "pointer",
                    fontWeight: 700,
                  }}
                  onClick={() => {
                    setSelectedCategories([]);
                    setFilteredPlatforms([])
                  }}
                >
                  View All
                </Typography>
              </Box>
              <Box
                sx={{
                  overflowY: 'auto',
                  px: 2,
                  flex: "1 1 0",
                }}
              >
                {allCategories?.map((catItem, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: "1rem",

                    }}
                  >
                    <Typography
                      className="heading"
                      sx={{
                        fontSize: "1rem",
                        mb: 1,
                        cursor: "pointer",
                        fontFamily: "Helvetica Neue",
                        color: `rgba(18, 18, 18, 0.9)`,
                        fontWeight: 500,
                      }}
                    >
                      {catItem.name}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {catItem?.subCategories?.map((subCat, index) => (
                        (subCat.name !== "Both" ?
                          <Typography
                            key={index}
                            sx={{
                              fontSize: "0.875rem",
                              color: "#666666",
                              mb: 1,
                              fontWeight: selectedCategories?.includes(subCat.id)
                                ? 700
                                : 400,
                              cursor: "pointer",
                              fontFamily: "Helvetica Neue",
                            }}
                            onClick={() => {
                              if (!selectedCategories?.includes(subCat.id)) {
                                setSelectedCategories(prev => [...prev, subCat.id]);

                              } else {
                                setSelectedCategories(prev =>
                                  prev.filter(scItem => scItem !== subCat.id)
                                );
                              }
                            }}
                          >
                            {subCat.name}
                          </Typography>
                          : ""
                        )
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box> : ""}

          <Box
            sx={{
              // width:isRequestedPlaform ?"88%" : "72%",
              // mx: "1.5rem",

              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow:
                "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
              flex: "1 1 0%",
              marginLeft: isRequestedPlaform ? "16px" : "0px",


            }}
          >
            {isRequestedPlaform ? (
              <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Box
                  sx={{
                    height: "3.125rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    className="heading"
                    sx={{ fontSize: "1.2rem", fontWeight: 700 }}
                  >
                    <img
                      alt="<"
                      src={leftArrow}
                      style={{
                        width: "0.5rem",
                        height: "0.75rem",
                        marginRight: "0.625rem",
                        marginBottom: 3,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setName("");
                        setUrl("");
                        setIsRequestedPlatform(false);
                        setRequestError("");
                      }}
                    ></img>
                    <span
                      className="heading"
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: 700,
                        fontFamily: "Helvetica Neue",
                        cursor: "pointer",
                        marginTop: "1rem",
                      }}
                      onClick={(e) => {
                        setName("");
                        setUrl("");
                        setIsRequestedPlatform(false);
                        setRequestError("");
                      }}
                    >
                      Back
                    </span>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="request-platform-container">
                    <Box
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      {validateRequestPlatformForm ? (
                        <span className="form-error">
                          {Strings.wrong_input}
                        </span>
                      ) : (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "red",
                            textAlign: "center",
                            height: "1.25rem",
                          }}
                        >
                          {requestError}
                        </Typography>
                      )}
                    </Box>

                    <p
                      className="text-center mb-2"
                      style={{ fontSize: "1.5rem", fontWeight: 700 }}
                    >
                      {Strings.request_platform}
                    </p>
                    <p className="text-center mb-4 sub-text-color">
                      Don’t see a platform you need? Request a platform below.
                    </p>
                    <div className="">
                      <CommonInput
                        height={"2.75rem"}
                        backgroundColor={"white"}
                        placeholder={Strings.name}
                        inputType={"text"}
                        value={name}
                        onChange={(event) => {
                          setRequestError("");
                          setName(event.target.value);
                        }}
                      ></CommonInput>
                    </div>
                    <div className="mt-3">
                      <CommonInput
                        height={"2.75rem"}
                        backgroundColor={"white"}
                        placeholder={Strings.url}
                        inputType={"text"}
                        value={url}
                        onChange={(event) => {
                          setRequestError("");
                          setUrl(event.target.value);
                        }}
                      ></CommonInput>
                    </div>
                    <div className="mt-3 mb-4">
                      <CommonButton
                        width={`100%`}
                        height={"2.75rem"}
                        textStyle={{
                          fontSize: "1.125rem",
                          fontWeight: "500",
                        }}
                        onClick={(event) => {
                          _requestNewPlatform(event);

                        }}
                        title={Strings.request}
                        color={color.buttonColor}
                        isLoading={requestPlatformLoading}
                        disabled={!name || !url}
                      ></CommonButton>
                    </div>
                  </div>
                </Box>

              </Box>

            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%"
                }}
              >
                <Box
                  sx={{
                    display: isSmallScreen ? "block" : "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    paddingTop: "1rem",
                    paddingRight: "1rem",
                    paddingLeft: "1rem",
                    // border: '1px solid black'
                    // height: "12%"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="<"
                      src={leftArrow}
                      style={{
                        width: "0.5rem",
                        height: "0.75rem",
                        marginRight: 20,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        navigate(-1)
                        // dispatch(setIsMyplatforms("My Platform"));
                      }}
                    ></img>
                    <span
                      className="heading"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: 700,
                        fontFamily: "Helvetica Neue",
                        letterSpacing: "0.2px",
                        marginTop: 3,
                      }}
                    >
                      Add Platform
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: isSmallScreen ? "space-around" : "space-between",
                      margin: isSmallScreen ? "10px" : "",
                      textAlign: "center",
                      // border: '1px solid black'
                    }}
                  >
                    {/* <img alt='' src={sortDown} style={{ width: "1.5rem", height: "1.5rem", marginRight: 20 }}></img> */}
                    <Button
                      onClick={() => {
                        setIsRequestedPlatform(true);
                      }}
                      style={{
                        marginRight: "1.25rem",
                        cursor: "pointer",
                        border: "1px solid #3646AC",
                        backgroundColor: color.whiteColor,
                        width: 165,
                        boxShadow: "none",
                        height: "2.75rem",
                        color: color.buttonColor,
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        padding: "0"
                      }}
                      variant="contained"
                    >
                      <Typography
                        style={{
                          fontSize: "1rem",
                          fontWeight: 600,
                          fontFamily: "Helvetica",
                          // marginTop: "0.125rem",
                        }}
                      >
                        {Strings.requested_platform}
                      </Typography>
                    </Button>
                    <OutlinedInput
                      value={searchValue}
                      onChange={(e) =>
                        setSearchValue(e.target.value)
                      }
                      placeholder="Search Here"
                      inputProps={{
                        style: {
                          paddingLeft: "1rem", padding: "5px",
                          fontFamily: "Helvetica",
                          fontWeight: 600
                        },
                      }}
                      sx={{
                        backgroundColor: "#fff",
                        height: "2.75rem",
                        alignItems: 'center',
                      }}
                      startAdornment={
                        <img
                          alt=""
                          src={searchImg}
                          style={{ width: "1.25rem", height: "1.25rem" }}
                        ></img>
                      }
                    ></OutlinedInput>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowY: "scroll",
                    // height: "88%",
                    flex: "1 1 0"
                  }}
                  className="cls-for-scroll"
                  onScroll={handleContainerScroll}
                >
                  <Box sx={{ height: "100%", width: '100%', overflowY: "scroll" }}>
                    {data !== undefined ? (
                      <Box sx={{ paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: "1rem" }}>
                        <InfiniteScroll
                          dataLength={platfomrs?.slice(0, proceedIndex + size)?.length}
                          next={fetchMoreData}

                          hasMore={platfomrs?.slice(0, proceedIndex + size)?.length < platfomrs?.length}
                          loader={<Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              // padding :"35px 0px"
                              marginBottom: "50px",
                              marginTop: "50px"
                            }}
                          >
                            {loading && (
                              <CircularProgress variant="indeterminate" sx={{ color: color.loadingColor }} disableShrink size={45} thickness={8} />
                            )}
                          </Box>}
                        >
                          <Grid container rowSpacing={3} columnSpacing={3} sx={{ margin: "0px !important", width: "auto !important" }}>
                            {filteredPlatforms?.length === 0 ? <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  // padding :"35px 0px"
                                  marginBottom: "50px",
                                  marginTop: "50px",
                                  height: "66vh"
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={slash}
                                    style={{
                                      width: "4rem",
                                      height: "4rem",
                                      marginBottom: 10,
                                    }}
                                  ></img>
                                  <Typography
                                    sx={{
                                      color: color.themeGray,
                                      fontSize: "1rem",
                                      letterSpacing: 1,
                                      fontWeight: 500,
                                    }}
                                  >
                                    No platform is Available
                                  </Typography>
                                </Box>
                              </Box>
                            </>
                              :
                              filteredPlatforms?.map((item, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}

                                  lg={3}
                                  key={index}
                                  style={{
                                    maxWidth: matchexl
                                      ? "16.875rem"
                                      : undefined,
                                    padding: "8px"
                                  }}
                                // onClick={(e) => {
                                //     if (!selectedPlatformIds.includes(item?.id)) {
                                //         setSelectedPlatform([...selectedPlatform, item])
                                //     }
                                // }}
                                >
                                  <Item
                                    className="GridMediaQuery"
                                    sx={{
                                      backgroundColor:
                                        selectedPlatformIds?.includes(item?.id)
                                          ? "rgba(25, 50, 65, 0.02)"
                                          : "rgba(18, 18, 18, 0.01)",
                                      border: selectedPlatformIds?.includes(
                                        item?.id
                                      )
                                        ? `1px solid ${"rgba(25, 50, 65, 0.5)"}`
                                        : `1px solid ${color.themeLightGray}`,
                                    }}
                                    // onClick={() => {
                                    //     if (item?.docUrl) {
                                    //         window.open(item?.docUrl)
                                    //     }
                                    // }}
                                    onMouseOver={() =>
                                      setHoverItemId(index + 1)
                                    }
                                    onMouseLeave={() => setHoverItemId(null)}
                                  >
                                    <img
                                      alt=""
                                      src={item?.logo}
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "60%",
                                      }}
                                    ></img>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "absolute",
                                        maxWidth: "70%",
                                        top: 80,
                                        right: 12,
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Tooltip title={<span style={{
                                        textTransform: "capitalize",
                                        fontSize: "12px"
                                      }}>{item?.dataType ? item?.dataType : ""}</span>} placeholder="top">
                                        <span style={{
                                          color: "#000000c2", fontWeight: 600,
                                          fontFamily: "Helvetica Neue",
                                          textTransform: "capitalize",
                                          cursor: "default",
                                          fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                                        }}>{item?.dataType ? item?.dataType : ""}</span>
                                      </Tooltip>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "absolute",
                                        maxWidth: "70%",
                                        top: 5,
                                        left: 4,
                                        flexDirection: "row",
                                        paddingTop: "2px",
                                        paddingLeft: '6px'
                                      }}
                                    >
                                      <span style={{
                                        color: "#000000c2", fontWeight: 600,
                                        fontFamily: "Helvetica Neue",
                                        textTransform: "capitalize",
                                        cursor: "default",
                                        fontSize: "10px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                      }}>{item?.supportedType ? item?.supportedType?.toLowerCase() : ""}
                                      </span>
                                    </Box>
                                    {hoverItemId &&
                                      hoverItemId === index + 1 ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          position: "absolute",
                                          top: 5,
                                          right: 10,
                                          backgroundColor: "#FFFFFF",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <HtmlTooltip
                                          title={
                                            <Typography
                                              style={{
                                                fontWeight: 400,
                                                fontFamily: "Helvetica Neue",
                                                fontSize: "0.75rem",
                                              }}
                                            >
                                              Platform Info
                                            </Typography>
                                          }
                                          arrow
                                          placement="top"
                                        >
                                          <Box
                                            sx={{
                                              width: "1rem",
                                              height: "1rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setStateName(isMyplatforms);
                                              dispatch(
                                                setIsMyplatforms(
                                                  "Configuration"
                                                )
                                              );
                                              const type = item?.dataType ? item?.dataType : ""
                                              navigate(selectedAccount
                                                ? `/platform/${item?.id}?dataType=${type}&dataTypeId=${item?.dataTypeId}&accountId=` + selectedAccount.id
                                                : `/platform/${item?.id}?dataType=${type}&dataTypeId=${item?.dataTypeId}`,
                                                { state: { platform: true, connectorId: item?.connectorId } })
                                              dispatch(
                                                setPlatformId({
                                                  id: item?.id,
                                                  logo: item?.logo,
                                                  name: item?.name,
                                                  docUrl: item?.docUrl,
                                                  overviewUrl: item?.overviewUrl,
                                                })
                                              );
                                              window.scrollTo({
                                                behavior: "auto",
                                                top: 0,
                                              });
                                            }}
                                          >
                                            <img src={infoLogo} />
                                          </Box>
                                        </HtmlTooltip>
                                        {selectedPlatformIds?.some(obj => obj.connectorId === item?.connectorId) ? (
                                          <HtmlTooltip
                                            title={
                                              <Typography
                                                style={{
                                                  fontWeight: 400,
                                                  fontFamily: "Helvetica",
                                                  fontSize: "0.75rem",
                                                }}
                                              >
                                                Delete
                                              </Typography>
                                            }
                                            arrow
                                            placement="top"
                                          >
                                            <Box
                                              sx={{
                                                width: "1rem",
                                                height: "1rem",
                                                ml: "1rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                if (item?.logo !== undefined) {
                                                  setIsDeletePlatformModal(
                                                    true
                                                  );
                                                  setSelectedItem(item);
                                                  // _deleteSelectedPlatform(item?.id)
                                                  // setSelectedPlatform(selectedPlatform.filter(el =>
                                                  //     el.id !== item?.id))
                                                } else {
                                                  // API call
                                                  _deleteRequestedPlatform(
                                                    item?.id
                                                  );
                                                }
                                              }}
                                            >
                                              <img src={negativeLogo} />
                                            </Box>
                                          </HtmlTooltip>
                                        ) : selectPlatformLoading ? (
                                          <Box
                                            sx={{
                                              width: "1rem",
                                              height: "1rem",
                                              ml: "1rem",
                                              pt: 0.4,
                                            }}
                                          >
                                            <CircularProgress 
                                              variant="indeterminate" 
                                              sx={{ color: color.loadingColor }}
                                              disableShrink
                                              size={15}
                                              thickness={4}
                                            ></CircularProgress>
                                          </Box>
                                        ) : (
                                          <HtmlTooltip
                                            title={
                                              <Typography
                                                style={{
                                                  fontWeight: 400,
                                                  fontFamily: "Helvetica Neue",
                                                  fontSize: "0.75rem",
                                                }}
                                              >
                                                Add
                                              </Typography>
                                            }
                                            arrow
                                            placement="top"
                                          >
                                            <Box
                                              sx={{
                                                width: "1rem",
                                                height: "1rem",
                                                ml: "1rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                if (
                                                  !selectedPlatformIds?.some(obj => obj.connectorId === item?.connectorId)
                                                ) {
                                                  handleSelectPlatform(item?.id, item?.dataTypeId, item?.dataType);
                                                  setSelectedPlatform([...selectedPlatform, item])
                                                  setSelectedItem(item);
                                                  dispatch(
                                                    setPlatformId({
                                                      id: item?.id,
                                                      logo: item?.logo,
                                                      name: item?.name,
                                                      docUrl: item?.docUrl,
                                                      overviewUrl:
                                                        item?.overviewUrl,
                                                      dataTypeId: item?.dataTypeId,
                                                      dataType: item?.dataType

                                                    })
                                                  );
                                                  setIsConfigurationOpen(true);
                                                }
                                              }}
                                            >
                                              <img src={positiveLogo} />
                                            </Box>
                                          </HtmlTooltip>
                                        )}
                                      </Box>
                                    ) : (
                                      item?.status === 2 && (
                                        <Box
                                          sx={{
                                            pt: 0.1,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            position: "absolute",
                                            top: 8,
                                            right: 8,
                                            width: "4.875rem",
                                            height: "1.3125rem",
                                            border: "1px solid #3646AC",
                                            borderRadius: 5,
                                            backgroundColor: "#FFFFFF",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontWeight: 400,
                                              fontFamily: "Helvetica Neue",
                                              fontSize: "0.625rem",
                                              color: "#3646AC",
                                            }}
                                          >
                                            Coming Soon
                                          </Typography>
                                        </Box>
                                      )
                                    )}
                                  </Item>
                                </Grid>
                              )
                              )}
                          </Grid>
                        </InfiniteScroll>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: "100px",
                          marginTop: "20px"
                        }}
                      >
                        <CircularProgress 
                          variant="indeterminate" 
                          sx={{ color: color.loadingColor }}
                          disableShrink
                          size={45}
                          thickness={8}
                        ></CircularProgress>
                      </Box>

                    )
                    }
                  </Box>


                  {!hasMore && <h1></h1>}

                  {/* <CommonButton
                                                  width={240}
                                                  height={56}
                                                  textStyle={{
                                                      fontSize: '1.125rem',
                                                      fontWeight: '500',
                                                      fontFamily: 'Helvetica Neue'
                                                  }}
                                                  isLoading={selectPlatformLoading}
                                                  onClick={handleSelectPlatform}
                                                  // onClick={(event) => {
                                                  //     dispatch(setSelectedPlatforms(selectedPlatform))
                                                  //     setStep(3);
                                                  // }}
                                                  title={'Save'}
                                                  color={selectedPlatform.length === 0 ? color.themeLightGray : color.buttonColor}
                                                  disabled={selectedPlatform.length === 0 || selectPlatformLoading}
                                              ></CommonButton> */}
                </Box>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              pt: "1rem",
              paddingLeft: "8px",
              display: "flex",
              flexDirection: "column",
              maxWidth: "24%",
              background: "#fff",
              borderRadius: 2,
              border: "1px solid rgba(54, 70, 172, 0.25)",
              flex: 0.8,
              mr: "16px"
            }}
          >
            <Box sx={{ mb: "1rem" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: color.black,
                  fontSize: "1.25rem",
                  fontWeight: 700,
                  fontFamily: "Helvetica Neue",
                  letterSpacing: "0.2px",
                  ml: "0.5rem",
                }}
              >
                {isRequestedPlaform ? "Requested Platforms" : "Selected Platforms"}
              </Typography>
            </Box>
            <Box
              sx={{
                overflowY: "scroll",
                borderRadius: 1,
                flex: "1 1 0",
                // height: isCOntentHieght ? "74vh" : "90vh",
                pr: selectedPlatformIds.length > 12 ? 0.8 : 0,
                // border: `1px solid ${color.themeLightGray}`
              }}
            >
              {selectedPlatformLoading ? <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "100px",
                  marginTop: "20px"
                }}
              >
                <CircularProgress 
                  variant="indeterminate" 
                  sx={{ color: color.loadingColor }}
                  disableShrink
                  size={45}
                  thickness={8}
                ></CircularProgress>
              </Box>
                :
                (SelectedPlatform?.length) > 0 ? (
                  SelectedPlatform?.map((item, idx) => (
                    <Box
                      key={idx}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        p: "0.5rem",
                        height: "3.75rem",
                        mb: 1,
                        borderRadius: 2,
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid rgba(54, 70, 172, 0.06)",
                        width: "100%",
                        gap: "10px",
                        backgroundColor: color.lightThemeColor,

                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "90%"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: "2.5rem",
                            maxWidth: "2.5rem",
                            height: "2.5rem",
                            borderRadius: 1,
                            backgroundColor: "#fff",
                          }}
                        >
                          {!isRequestedPlaform ? (
                            <img
                              alt=""
                              src={getFavIconImagePath(item?.logo)}
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            ></img>

                          ) : (
                            <img
                              alt=""
                              src={connectionLogo}
                              style={{ width: "2rem", height: "2rem" }}
                            ></img>
                          )}
                        </Box>
                        <Box
                          sx={{
                            ml: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%", overflow: "hidden",
                          }}
                        >
                          <Typography
                            sx={{
                              color: color.black,
                              fontSize: isSmallForTableHeader ? "11px" : "16px",
                              fontWeight: 500,
                              fontFamily: "Helvetica Neue",
                            }}
                          >
                            {item?.name}
                            <Box sx={{ width: "100%", display: "flex", alignItems: "end", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }}>
                              <Tooltip title={<span style={{
                                textTransform: "capitalize",
                                fontSize: "12px"
                              }}>{item?.dataType ? item?.dataType : ""}</span>} placeholder="top">
                                <span style={{
                                  textTransform: "capitalize",
                                  color: "#000000c2", fontWeight: 500,
                                  fontFamily: "Helvetica Neue",
                                  cursor: "default",
                                  fontSize: "10px",

                                }}>{item?.dataType ? item?.dataType : ""}</span>
                              </Tooltip>
                            </Box>
                          </Typography>
                          {item?.logo === undefined && (
                            // <Typography sx={{ color: color.black, fontSize: "0.75rem", fontWeight: 500, fontFamily: 'Helvetica Neue' }}>
                            //     Lorem Ipsum is fake text
                            // </Typography> :
                            <Typography
                              sx={{
                                color: color.buttonColor,
                                fontSize: "0.75rem",
                                fontWeight: 500,
                                fontFamily: "Helvetica Neue",
                              }}
                            >
                              Requested
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <ClearIcon
                        sx={{
                          width: "10%",
                          color: "#3646AC",
                          height: "1.25rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (item?.logo !== undefined) {
                            setIsDeletePlatformModal(true);
                            setSelectedItem(item);
                            // _deleteSelectedPlatform(item?.id)
                            // setSelectedPlatform(selectedPlatform.filter(el =>
                            //     el.id !== item?.id))
                          } else {
                            // API call
                            _deleteRequestedPlatform(item?.id);
                          }
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src={slash}
                      style={{
                        width: "4rem",
                        height: "4rem",
                        marginBottom: 10,
                      }}
                    ></img>
                    <Typography
                      sx={{
                        color: color.themeGray,
                        fontSize: "1rem",
                        letterSpacing: 1,
                        fontWeight: 500,
                      }}
                    >
                      No platform is selected
                    </Typography>
                  </Box>
                )
              }
            </Box>
          </Box>

        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isDeleteModal}
          onClose={() => setIsDeleteModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isDeleteModal}>
            <Box sx={{ ...style, height: "15.5rem" }}>
              {selectedItem && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        p: "0.5rem",
                        width: "100%",
                        height: "3.75rem",
                        borderRadius: 1,
                        //   border: "1px solid rgba(54, 70, 172, 0.15)",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "2.5rem",
                          borderRadius: 1,
                          // backgroundColor: "rgba(54, 70, 172, 0.07)",
                          p: 0.4,
                        }}
                      >
                        <img
                          alt=""
                          src={isRequestedPlaform ? selectedItem?.logo : `${selectedItem?.logo}`}
                          // src='http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Freewheel.svg'
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></img>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {/* <Box sx={{ width: 68, height: 68, mb: 3 }}>
                                  <img src={ModalAlertIcons} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                              </Box> */}
              <Typography
                style={{
                  // fontWeight: 700, width: '80%',
                  // fontFamily: 'Helvetica Neue', fontSize: "1.25rem",
                  // textAlign: 'center', color: '#25262D'
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "1rem",
                  textAlign: "center",
                  color: "#656C7B",
                  width: "80%",
                }}
              >
                {deleteErrorMsg}
                {/* Cannot delete the platform as it is being used in a connection */}
              </Typography>
              {/* <Typography sx={{
                                  fontWeight: 400,
                                  fontFamily: 'Helvetica Neue', fontSize: "1rem",
                                  textAlign: 'center', color: '#656C7B',
                                  width: '70%',
                              }}>
                                  {deleteErrorMsg}
                              </Typography> */}
              <Box sx={{ mt: 3 }}>
                <CommonButton
                  width={"4.375rem"}
                  height={"2.25rem"}
                  textStyle={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    fontFamily: "Helvetica Neue",
                  }}
                  onClick={() => setIsDeleteModal(false)}
                  title={"Okay"}
                  color={color.buttonColor}
                ></CommonButton>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Delete Platform modal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isDeletePlatformModal}
          onClose={() => {
            setIsDeletePlatformModal(false);
            setSelectedItem([]);
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isDeletePlatformModal}>
            <Box sx={{ ...style, width: "31.25rem" }}>
              {selectedItem && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        p: "0.5rem",
                        width: "100%",
                        height: "3.75rem",
                        borderRadius: 1,
                        //   border: "1px solid rgba(54, 70, 172, 0.15)",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "2.5rem",
                          borderRadius: 1,
                          // backgroundColor: "rgba(54, 70, 172, 0.07)",
                          p: 0.4,
                        }}
                      >
                        {!isRequestedPlaform ? (
                          <img
                            alt=""
                            src={logoUrl}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          ></img>
                        ) : (
                          <img
                            alt=""
                            src={connectionLogo}
                            style={{ width: "2rem", height: "2rem" }}
                          ></img>

                        )}
                        {!isRequestedPlaform ? "" : <span style={{ fontWeight: "bold", marginLeft: "10px", fontSize: "1.5rem", color: "#979CA8" }}>{selectedItem?.name}</span>}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Typography
                style={{
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "1rem",
                  textAlign: "center",
                  color: "#656C7B",
                  marginBottom: "1rem",
                  // width: '70%'
                }}
              >
                Are you sure you want to remove this platform?
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <FormControl
                  sx={{
                    width: "70%",
                    height: "2.75rem",
                    fontSize: "1rem",
                    color: "#666666",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Helvetica Neue",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  size="small"
                >
                  <InputLabel
                    sx={{
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      opacity: 0.5,
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Helvetica Neue",
                    }}
                    id="delete"
                  >
                    Type DELETE to confirm
                  </InputLabel>
                  <OutlinedInput
                    placeholder={""}
                    sx={{
                      width: "100%",
                      height: "2.75rem",
                      fontSize: "1rem",
                      color: "#666666",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Helvetica Neue",
                    }}
                    labelid="delete"
                    id="delete"
                    label="Type DELETE to confirm"
                    onChange={handleInputChange}
                    value={inputText}
                    autoComplete="off"
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  mt: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '1rem'
                }}
              >
                <Button
                  onClick={() => {
                    if (!isRequestedPlaform) {
                      _deleteSelectedPlatform(selectedItem?.id, selectedItem?.dataTypeId);
                      setSelectedItem(selectedItem);
                    } else {
                      _deleteRequestedPlatform(selectedItem?.id)
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    // backgroundColor: color.whiteColor,
                    backgroundColor: isDeleteButtonEnabled
                      ? color.whiteColor
                      : "#CCCCCC",
                    width: "7rem",
                    height: "2.5rem",
                    color: isDeleteButtonEnabled ? color.themeRed : "#fff",
                    textTransform: "none",
                    border: "1.5px solid",
                    borderColor: isDeleteButtonEnabled
                      ? color.themeRed
                      : "transparent",
                  }}
                  disabled={!isDeleteButtonEnabled}
                  variant="contained"
                >
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <img
                      alt=""
                      src={trash}
                      style={{
                        width: "1.3rem",
                        display: "flex",
                        alignItems: "center",
                        filter: !isDeleteButtonEnabled && "grayscale(100%)",
                      }}
                    ></img>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        fontFamily: "Helvetica",
                        display: "flex",
                        alignItems: "center",
                        // marginTop: 2,
                      }}
                    >
                      Delete
                    </Typography>
                  </Box>
                </Button>
                {/* <Button
                    onClick={() => {
                      setIsDeletePlatformModal(false);
                      setSelectedItem([]);
                    }}
                    style={{
                      cursor: "pointer",
                      border: `1px solid ${color.buttonColor}`,
                      backgroundColor: color.whiteColor,
                      width: "5.625rem",
                      height: "2.5rem",
                      color: color.buttonColor,
                      textTransform: "none",
                    }}
                    variant="contained"
                  >
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        fontFamily: "Helvetica Neue",
                        marginTop: "0.125rem",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Button> */}

                <Button
                  onClick={() => {
                    setIsDeletePlatformModal(false);
                    setSelectedItem([]);
                  }}
                  style={{
                    cursor: "pointer",
                    // border: `1px solid ${color.themeDarkGray}`,
                    backgroundColor: color.whiteColor,
                    width: "6.625rem",
                    height: "2.5rem",
                    color: color.themeBlack,
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <img
                      alt=""
                      src={cancel}
                      style={{
                        width: "0.8rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></img>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        fontFamily: "Helvetica",
                        display: "flex",
                        alignItems: "center",
                        // marginTop: 2,
                      }}
                    >
                      Cancel
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Configuration Madal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isConfigurationOpen}
          // onClose={() => setIsConfigurationOpen(false)}
          onClose={() => {
            setIsConfigurationOpen(false);
            setSelectedItem([]);
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isConfigurationOpen}>
            <Box sx={{ ...style, height: "12.5rem" }}>
              <Typography
                style={{
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "1rem",
                  textAlign: "center",
                  color: "#656C7B",
                  width: "70%",
                }}
              >
                You need to configure your account with {selectedItem?.name} to
                use in the connection. Do you wish to configure it now?
              </Typography>
              <Box
                sx={{
                  width: "40%",
                  mt: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => setIsConfigurationOpen(false)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: color.buttonColor,
                    width: "auto",
                    height: "2.5rem",
                    marginRight: "0.625rem",
                    color: color.whiteColor,
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    Skip
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    setStateName(isMyplatforms);
                    navigate(selectedAccount
                      ? `/platform/${platformId?.id}?dataType=${platformId?.dataType}&dataTypeId=${selectedItem?.dataTypeId}&accountId=` +
                      selectedAccount.id
                      : `/platform/${platformId?.id}?dataType=${platformId?.dataType}&dataTypeId=${selectedItem?.dataTypeId}`,)
                    // dispatch(setIsMyplatforms("Configuration"));
                    dispatch(
                      setPlatformId({
                        id: platformId.id,
                        logo: platformId.logo,
                        name: platformId.name,
                        docUrl: platformId.docUrl,
                        overviewUrl: platformId.overviewUrl,
                        dataType: platformId.dataType
                      })
                    );
                    setIsConfigurationOpen(false);
                    window.scrollTo({ behavior: "auto", top: 0 });
                  }}
                  style={{
                    cursor: "pointer",
                    border: `1px solid ${color.buttonColor}`,
                    backgroundColor: color.whiteColor,
                    width: "auto",
                    height: "2.5rem",
                    color: color.buttonColor,
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    Configure
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        {/* <Button variant="outlined" onClick={handleClick}>
          Open success snackbar
        </Button> */}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            <span> Your request for <span style={{ fontWeight: "bold" }}>{sucess.name} </span>has been received.</span>
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </AppLayout >
  );
};

export default Platform;

{
  /* <Steps
                ref={stepRef}
                enabled={true}
                steps={steps}
                initialStep={0}
                onExit={() => console.log('step complete')}
            />
            <Hints enabled={true} hints={hints} /> */
}
{
  /* <Box sx={{ display: "flex", flexDirection: "row", border: "1px solid black" }}>
                <Box sx={{ width: '100%', height: '100vh', border: "1px solid black" }}>
 
                </Box>
                <Box> */
}

// const user = JSON.parse(localStorage.getItem('USER'))
// const stepRef = useRef(null)
// const [allplatforms, setAllplatforms] = useState([])
// const [searchValue, setSearchValue] = useState('')
// const { data, isLoading, error } = useFetchPartnerQuery()

// useEffect(() => {
//     if (data !== undefined && data.response) {
//         // console.log('data', data);
//         setAllplatforms(data.response.platforms);
//     }
//     if (error !== undefined) {
//         console.log('error', error);
//     }
// }, [data, error])

// useEffect(() => {
//     if (!searchValue && data !== undefined && data.response) {
//         setAllplatforms(data.response.platforms);
//     }
// }, [searchValue, data])

// const [steps, setSteps] = useState([
//     {
//         element: ".heading",
//         intro: "This is your message to your user"
//     },
//     {
//         element: ".search",
//         intro: "This is search"
//     }
// ])
// const [hints, setHints] = useState([{
//     element: ".search",
//     hint: "Hello hint",
//     hintPosition: "middle-right"
// }])
// useEffect(() => {
//     if (stepRef) {
//         console.log('stepRef', stepRef.current.introJs.setOption("skipLabel", "Exit"));
//         stepRef.current.introJs.nextStep()
//     }
// }, [stepRef])

{
  /* <>
            <Box sx={{ mb: "1.5rem", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography className='heading' sx={{ fontSize: "1.6rem", fontWeight: 700 }}>Integration</Typography>
                <Search className='search'>
                    <SearchIconWrapper>
                        <img alt='' src={searchImg} style={{ width: "1.25rem", height: "1.25rem" }}></img>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search here"
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value)
                            setAllplatfomrs(allplatfomrs.filter(item =>
                                item?.name.toLowerCase().startsWith(e.target.value.toLowerCase())))
                        }}
                    />
                </Search>
            </Box>
            <Box>
                {data !== undefined &&
                    <Grid container spacing={{ xs: 4, sm: 4, md: 4 }}>
                        {allplatfomrs.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Item>
                                    <img alt='' src={item?.logo}
                                        style={{ maxWidth: '100%', maxHeight: '100%' }}></img>
                                    {item?.integrated &&
                                        <img alt='' src={checkImg}
                                            style={{
                                                width: "1.5rem", height: "1.5rem",
                                                position: "absolute",
                                                top: 10, left: 10
                                            }}></img>
                                    }
                                </Item>
                            </Grid>
                        ))}
                    </Grid>
                }
            </Box>
            </> */
}
