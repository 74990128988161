import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { madTechState } from "../../features/madTechSlice";
import { useNavigate } from "react-router-dom";

const SBreadCrumb = ({
	mainPathName,
	mainPath = "",
	pathName,
	secondaryPathName,
	ModulepathName,
	modulePath,
	state,
	setState,
	onMainPathNameClick = null,
}) => {
	const { selectedAccount } = useSelector(madTechState);
	const navigate = useNavigate();

	const handleMainClick = useCallback(() => {
		if (!mainPath) return;
		if (onMainPathNameClick) {
			onMainPathNameClick();
		}
		navigate(
			selectedAccount
				? `/${
						mainPathName === "Settings" ? `profileDetails` : mainPath
				  }?accountId=` + selectedAccount.id
				: `/${mainPathName === "Settings" ? `myprofile` : mainPath}`
		);
	}, [onMainPathNameClick, mainPath, selectedAccount, mainPathName]);

	const handleModuleClick = () => {
		navigate(
			selectedAccount
				? `/${modulePath}?accountId=` + selectedAccount.id
				: `/${modulePath}`
		);
	};

	return (
		<Box display="flex" alignItems="center">
			<Typography
				onClick={handleMainClick}
				sx={{
					fontSize: "1.25rem",
					fontFamily: "Helvetica Neue",
					cursor: mainPath ? "pointer" : "default",
					color: pathName ? "#8f8d8d" : "#212529",
				}}
			>
				{mainPathName}
			</Typography>
			{ModulepathName && (
				<Box display="flex" alignItems="center">
					<ChevronRightIcon
						sx={{
							height: "1.25rem",
							width: "1.25rem",
							color: "#8f8d8d",
							margin: "0px 8px 0px 8px",
						}}
					/>
					<Typography
						onClick={handleModuleClick}
						sx={{
							fontSize: "1.25rem",
							fontFamily: "Helvetica Neue",
							fontWeight: 500,
							letterSpacing: 0.2,
							color: "#8f8d8d",
							cursor: "pointer",
						}}
					>
						{ModulepathName}
					</Typography>
				</Box>
			)}
			<Box display="flex" alignItems="center">
				{pathName && (
					<>
						<ChevronRightIcon
							sx={{
								height: "1.25rem",
								width: "1.25rem",
								color: "#8f8d8d",
								margin: "0px 8px 0px 8px",
							}}
						/>
						<Typography
							sx={{
								fontSize: "1.25rem",
								fontFamily: "Helvetica Neue",
								fontWeight: 500,
								letterSpacing: 0.2,
								color: "#212529",
							}}
						>
							{pathName}{" "}
							{secondaryPathName ? (
								<span style={{ fontSize: "1rem" }}>({secondaryPathName})</span>
							) : (
								""
							)}
						</Typography>
					</>
				)}
				{ModulepathName && !state && (
					<Typography
						onClick={() => setState(true)}
						sx={{
							fontSize: "0.875rem",
							fontFamily: "Helvetica Neue",
							fontWeight: 500,
							ml: 1,
							color: "green",
							textDecoration: "underline",
							cursor: "pointer",
						}}
					>
						Edit
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default SBreadCrumb;
