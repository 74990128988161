import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import AppLayout from "../../../components/AppLayout/AppLayout";
import {
	useAddSUserMutation,
	useGetSRoleMutation,
} from "../../../services/query";
import { madTechState } from "../../../features/madTechSlice";
import SAddUser from "./SAddUser";
import { useMyContext } from "../../../components/CommanToasterContext/toast";
import { Strings } from "../../../utils/Strings";
import Loader from "../../../components/Setting/Loader";
import SBreadCrumb from "../../../components/Setting/SBreadCrumb";

const SAddUserForm = () => {
	const { selectedAccount } = useSelector(madTechState);

	const navigate = useNavigate();
	const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();

	const [userDetails, setUserDetails] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		companyName: selectedAccount?.name,
		title: "",
		roleId: "",
		accounts: [],
	});

	const [roles, setRoles] = useState(null);

	const [
		addSUser,
		{ data: addSUserData, error: addSUserError, isLoading: addUSerLoading },
	] = useAddSUserMutation();

	const [
		getRoleData,
		{ data: roleData, isLoading: roleLoading, error: roleError },
	] = useGetSRoleMutation();

	useEffect(() => {
		getRoleData();
	}, []);

	useEffect(() => {
		if (roleData !== undefined) {
			if (roleData.statusCode === 200 && roleData.response) {
				setRoles(roleData?.response);
				return;
			} else {
				setRoles([]);
				return;
			}
		}

		if (roleError !== undefined) {
			setRoles([]);
			console.log("roleError", roleError);
		}
	}, [roleData, roleError]);

	useEffect(() => {
		if (addSUserData !== undefined) {
			if (addSUserData.statusCode === 200) {
				setMessage(addSUserData?.statusMessage);
				setOpen(true);
				navigate(
					selectedAccount ? "/users?accountId=" + selectedAccount.id : "/users"
				);
				return;
			} else {
				setOpen2(true);
				setMessage2(addSUserData.statusMessage);
			}
		}
		if (addSUserError !== undefined) {
			console.log("addSUserError", addSUserError);
		}
	}, [addSUserData, addSUserError]);

	const _handleAddUser = (data) => {
		addSUser(data ? data : userDetails);
	};

	return (
		<AppLayout>
			<Box sx={{ px: "1.5rem" }}>
				<SBreadCrumb
					mainPathName="Settings"
					mainPath="myprofile"
					pathName="Add New User"
					ModulepathName="Users"
					modulePath="users"
					state={true}
				/>
				{roleLoading || roles == null ? (
					<Loader />
				) : (
					<SAddUser
						userDetails={userDetails}
						roles={roles}
						setUserDetails={setUserDetails}
						_handleAddUser={_handleAddUser}
						addUSerLoading={addUSerLoading}
					/>
				)}
			</Box>
		</AppLayout>
	);
};

export default SAddUserForm;
