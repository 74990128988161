import { Box } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppLayout from "../../components/AppLayout/AppLayout";
import { madTechState } from "../../features/madTechSlice";
import { color } from "../../utils/Colors";
import MyConnections from "./MyConnections/MyConnections";

const Connection = () => {
  const navigate = useNavigate();
  const [connectors, setConnectors] = useState(null);
  const { isLoggedin } = useSelector(madTechState);

  if (isLoggedin === "no") {
    setTimeout(() => {
      localStorage.clear();
       navigate("/signin");
      return;
    }, 10);
  }

  return (
    <AppLayout>
      <Box
        px="1.5rem"
        sx={{ minHeight: "100%" }}
        display={connectors ? "" : "contents"}
        backgroundColor={color.layoutBgColor}
      >
        <MyConnections connectors={connectors} setConnectors={setConnectors} />
      </Box>
    </AppLayout>
  );
};

export default Connection;
