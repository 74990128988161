import { Box, TableRow, Tooltip } from "@mui/material";
import { StyledTableCell, TableCellTypography } from "./index.styles";
import { useCallback, useMemo } from "react";
import SmallDot from "../../assets/SmallDot";
import {
	capitalizeWords,
	formatRows,
	formatTimestamp,
	formatWithCommas,
} from "../../utils/utils";

const ReportDataRow = ({ row }) => {
	const statusColorMap = useMemo(
		() => ({
			success: "#21D457",
			done: "#21D457",
			completed: "#21D457",
			failure: "#DF1907",
			failed: "#DF1907",
			in_progress: "#FA9F0E",
			"in-progress": "#FA9F0E",
			initialization: "#538BF4",
		}),
		[]
	);

	const getStatus = useCallback(
		(status) => {
			const color = statusColorMap?.[status];
			return (
				<Box
					sx={{
						color,
						width: "fit-content",
						display: "flex",
						alignItems: "center",
						gap: "8px",
					}}
				>
					<SmallDot color={color} />
					{capitalizeWords(status)}
				</Box>
			);
		},
		[statusColorMap]
	);

	return (
		<TableRow key={row.batchId}>
			<StyledTableCell>
				<TableCellTypography nested>
					{formatTimestamp(row.startTime)}
				</TableCellTypography>
			</StyledTableCell>
			{/* <StyledTableCell>
				<TableCellTypography nested>Sync</TableCellTypography>
			</StyledTableCell> */}
			<StyledTableCell>
				<TableCellTypography nested>{row.batchId}</TableCellTypography>
			</StyledTableCell>
			<StyledTableCell>
				<TableCellTypography>{getStatus(row.status)}</TableCellTypography>
			</StyledTableCell>
			<StyledTableCell>
				<TableCellTypography nested>
					{formatTimestamp(row.endTime)}
				</TableCellTypography>
			</StyledTableCell>
			<StyledTableCell>
				<TableCellTypography nested>
					<Tooltip
						title={formatWithCommas(row.totalRowsProcessed)}
						placement="bottom"
					>
						{formatRows(row.totalRowsProcessed)}
					</Tooltip>
				</TableCellTypography>
			</StyledTableCell>
			<StyledTableCell>
				<TableCellTypography nested>
					<Tooltip
						title={formatWithCommas(row.deliveredRows)}
						placement="bottom"
					>
						{formatRows(row.deliveredRows)}
					</Tooltip>
				</TableCellTypography>
			</StyledTableCell>
			<StyledTableCell>
				<TableCellTypography nested>
					<Tooltip title={formatWithCommas(row.failedRows)} placement="bottom">
						{formatRows(row.failedRows)}
					</Tooltip>
				</TableCellTypography>
			</StyledTableCell>
		</TableRow>
	);
};

export default ReportDataRow;
