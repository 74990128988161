import momentTz from "moment-timezone";
import React, {
	useCallback,
	useEffect,
	useState,
	Fragment,
	useMemo,
} from "react";
import {
	SearchBarWrapper,
	SearchInput,
	StickyTableHead,
	StyledTableCell,
	TableCellTypography,
	TableHeaderTypography,
	TableHeaderWrapper,
	TableWrapper,
} from "./index.styles";
import {
	Box,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	CircularProgress,
	Collapse,
	Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { DateRangePicker } from "react-dates";
import moment from "moment";

import searchIcon from "../../assets/searchIcon.svg";
import expandMoreIcon from "../../assets/expandMore.svg";
import downloadIcon from "../../assets/downloadReport.svg";

import AppLayout from "../../components/AppLayout/AppLayout";
import CommonDatePicker from "../../components/CommonDatePicker/CommonDatePicker";
// import Bradcurm from "../../components/CommsnBradcurm/bradcurm";
// import { DownloadImg } from "../../components/CoustomIcons";
// import { useThemeContext } from "../../components/ThemeContext/themeContext";

import { BASE_URL } from "../../utils/Baseurl";
import { color } from "../../utils/Colors";
import {
	useGetConnectionReportsMutation,
	useGetConnectionReportDataMutation,
} from "../../services/query";
import { Heading } from "../Dashboard/Dashboard.styles";
import { StyledLoaderBoxContain } from "../../components/AppLayout/AppLayout.styles";
import { useLocation } from "react-router-dom";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import Dot from "../../assets/dot";
import SmallDot from "../../assets/SmallDot";
import ReportDataRow from "./ReportDataRow";
import ReportConnections from "./ReportConnections";
import {
	formatRows,
	formatTimestamp,
	formatWithCommas,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import { madTechState } from "../../features/madTechSlice";

const Reports = () => {
	const [isCustom, setIsCustom] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [focusedInput, setFocusInput] = useState(null);
	const [timeLineTxt, setTimeLineTxt] = useState("Year to date");
	const [timemilliseconds, setTimeMilliseconds] = useState(15778800);
	const [reports, setReports] = useState(null);
	// const [rows, setRows] = useState(null);
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [searchValue, setSearchValue] = useState("");
	const [search, setSearch] = useState("");
	const [dateRange, setDateRange] = useState([
		dayjs("2023-05-15"),
		dayjs("2024-06-01"),
	]);
	const [expandedRow, setExpandedRow] = useState(null);
	const [selectedConnection, setSelectedConnection] = useState(null);
	const [accountId, setAccountId] = useState(null);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const { accountDetails } = useSelector(madTechState);
	const [
		getReports,
		{
			data: connectionReportsData,
			error: connectionReportsError,
			isLoading: connectionReportsLoading,
		},
	] = useGetConnectionReportsMutation();
	const [
		getReportData,
		{ data: reportData, error: reportDataError, isLoading: reportDataLoading },
	] = useGetConnectionReportDataMutation();

	const connectionReportsHeaders = useMemo(
		() => [
			{ id: 1, name: "" },
			{ id: 2, name: "Connection Name" },
			{ id: 3, name: "Source" },
			{ id: 4, name: "Destination" },
			{ id: 5, name: "Status" },
			{ id: 6, name: "First Activated" },
			{ id: 7, name: "Rows" },
			{ id: 8, name: "Actions" },
		],
		[]
	);
	const connectionReportDataHeaders = useMemo(
		() => [
			{ id: 1, name: "Start Time" },
			{ id: 2, name: "Batch" },
			{ id: 3, name: "Status" },
			{ id: 4, name: "End Time" },
			{ id: 5, name: "Total Rows" },
			{ id: 6, name: "Delivered" },
			{ id: 7, name: "Failed" },
		],
		[]
	);

	const colorMap = useMemo(
		() => ({
			"In-progress": { color: "#538BF4", background: "#ECF2FF" },
			Disabled: { color: "#A0A0A0", background: "#F3F3F3" },
			Deleted: { color: "#8B0000", background: "#FFEDED" },
			Offline: { color: "#FA9F0E", background: "#FEF8EE" },
			Active: { color: "#21D557", background: "#E8FFEF" },
		}),
		[]
	);

	const handleExpandBtnClick = useCallback(
		(rowId) => {
			const timezoneOffset =
				momentTz.tz.zone(momentTz.tz.guess()).offset(new Date()) * 60;

			const payload = {
				endDate: endTime,
				startDate: startTime,
				status: timeLineTxt,
				offset: timezoneOffset,
			};

			getReportData({ payload, connectionId: rowId });

			setExpandedRow((expandedRow) => (expandedRow === rowId ? null : rowId)); // Toggle the expanded row
		},
		[endTime, startTime, timeLineTxt]
	);

	useEffect(() => {
		const account_id = queryParams.get("accountId");
		if (!account_id) {
			setAccountId(accountDetails?.id);
		} else {
			setAccountId(account_id);
		}
	}, [accountDetails, queryParams, setAccountId]);

	useEffect(() => {
		if (timemilliseconds) {
			getAnalytics();
		}
	}, [timemilliseconds]);

	// useEffect(() => {
	//     if (
	//         connectionReportsData !== undefined &&
	//         connectionReportsData?.statusCode === 200 &&
	//         connectionReportsData?.response &&
	//         connectionReportsData?.response.adminReport
	//     ) {
	//         setReports(connectionReportsData?.response?.adminReport);
	//         setRows(connectionReportsData?.response?.adminReport);
	//         return
	//     }

	//     if (connectionReportsError !== undefined) {
	//         setReports([]);
	//         setRows([]);
	//         console.log("connectionReportsError", connectionReportsError);
	//     }
	// }, [connectionReportsData, connectionReportsError]);

	// const requestSearch = (searchedVal) => {
	//     const filteredRows = reports?.filter((row) => {
	//         return (
	//             row.connectionName.toLowerCase().includes(searchedVal.toLowerCase()) ||
	//             row.connectionId.toLowerCase().includes(searchedVal.toLowerCase())
	//         );
	//     });
	//     setRows(filteredRows);
	//     setPage(0);
	//     setSearchValue(searchedVal);
	// };

	// const handleChangePage = (event, newPage) => {
	//     setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (event) => {
	//     setRowsPerPage(parseInt(event.target.value, 10));
	//     setPage(0);
	// };

	const getAnalytics = useCallback(() => {
		let sDate = parseInt(Date.now() / 1000 - timemilliseconds);
		let eDate = parseInt(Date.now() / 1000);
		const timezoneOffset =
			momentTz.tz.zone(momentTz.tz.guess()).offset(new Date()) * 60;

		if (timeLineTxt === "Year to date") {
			// Get current date
			const today = new Date();
			// Get current year
			const year = today.getFullYear();
			// Get first day of year
			const yearFirstDay = new Date(year, 0, 1);
			let milliseconds = (today.getTime() - yearFirstDay.getTime()) / 1000;
			sDate = parseInt(Date.now() / 1000 - milliseconds);
		}
		setStartTime(sDate);
		setEndTime(eDate);
		getReports({
			endDate: eDate,
			startDate: sDate,
			status: timeLineTxt,
			offset: timezoneOffset,
		});
	}, [useGetConnectionReportsMutation, timemilliseconds, timeLineTxt]);

	const getAnalytics1 = useCallback(
		(startDate, endDate) => {
			setStartTime(startDate / 1000);
			setEndTime(endDate / 1000);
			const timezoneOffset =
				momentTz.tz.zone(momentTz.tz.guess()).offset(new Date()) * 60;
			getReports({
				endDate: parseInt(endDate / 1000),
				startDate: parseInt(startDate / 1000),
				status: timeLineTxt,
				offset: timezoneOffset,
			});
		},
		[useGetConnectionReportsMutation, timemilliseconds, timeLineTxt]
	);

	const _downloadReport = useCallback(
		(connectionId) => {
			const link = document.createElement("a");
			const timezoneOffset =
				momentTz.tz.zone(momentTz.tz.guess()).offset(new Date()) * 60;
			link.href = `${BASE_URL}/analytics/admin/report/download/${accountId}?startDate=${startTime}&endDate=${endTime}&status=${timeLineTxt}&connectionId=${connectionId}&offset=${timezoneOffset}`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		[BASE_URL, startTime, endTime, accountId]
	);

	const getStatusChip = useCallback(
		(status) => {
			return (
				<Box
					sx={{
						borderRadius: "6px",
						padding: "10px 12px",
						background: colorMap[status].background,
						color: colorMap[status].color,
						width: "fit-content",
						display: "flex",
						alignItems: "center",
						gap: "8px",
					}}
				>
					<Dot color={colorMap[status].color} />
					{status}
				</Box>
			);
		},
		[colorMap]
	);

	const handleSearch = (e) => setSearch(e.target.value);

	useEffect(() => {
		const filteredRows = connectionReportsData?.response
			? connectionReportsData.response.connectionReport.filter(
					(row) =>
						row.connectionName.toLowerCase().includes(search.toLowerCase()) ||
						row.sourceConnectorName
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						row.destinationConnectorName
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						row.connectionId.toLowerCase().includes(search.toLowerCase())
			  )
			: [];

		setReports(filteredRows);
	}, [connectionReportsData, search]);

	// useEffect(() => {
	// 	// Get all connection report data
	// 	if (!startDate || !endDate || !selectedConnection) return;

	// 	const today = new Date();
	// 	// Get current year
	// 	const year = today.getFullYear();
	// 	// Get first day of year
	// 	const yearFirstDay = new Date(year, 0, 1);
	// 	let milliseconds = (today.getTime() - yearFirstDay.getTime()) / 1000;
	// 	let sDate = parseInt(Date.now() / 1000 - milliseconds);
	// 	let eDate = parseInt(Date.now() / 1000);
	// 	const timezoneOffset =
	// 		momentTz.tz.zone(momentTz.tz.guess()).offset(new Date()) * 60;

	// 	const payload = {
	// 		endDate: eDate,
	// 		startDate: sDate,
	// 		status: timeLineTxt,
	// 		offset: timezoneOffset,
	// 	};

	// 	getReportData({ payload, connectionId: selectedConnection.connectionId });
	// }, [selectedConnection, endDate, startDate, timeLineTxt]);

	return (
		<AppLayout>
			{selectedConnection ? (
				<ReportConnections
					loading={reportDataLoading}
					data={reportData}
					selectedConnection={selectedConnection}
					setSelectedConnection={setSelectedConnection}
					connectionReportDataHeaders={connectionReportDataHeaders}
				/>
			) : (
				<TableWrapper>
					<TableHeaderWrapper>
						<SBreadCrumb mainPathName="Reports" mainPath="reports" />
						<Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
							{/* Search Bar */}
							<SearchBarWrapper>
								<img src={searchIcon} width={20} height={20} alt="search" />
								<SearchInput
									placeholder="Search"
									value={search}
									onChange={handleSearch}
								/>
							</SearchBarWrapper>

							{isCustom && (
								<Box
									display="flex"
									flexDirection="column"
									alignItems="end"
									flex="auto"
									mr={2}
									sx={{ zIndex: 11 }}
								>
									<DateRangePicker
										startDate={startDate}
										startDateId="startDate"
										endDate={endDate}
										endDateId="endDate"
										numberOfMonths={1}
										isOutsideRange={(day) => {
											return moment().diff(day) < 0;
										}}
										onDatesChange={({ startDate, endDate }) => {
											setStartDate(startDate);
											setEndDate(endDate);
											if (startDate !== null && endDate !== null) {
												getAnalytics1(startDate, endDate);
											}
										}}
										focusedInput={focusedInput}
										onFocusChange={(focusedInput) =>
											setFocusInput(focusedInput)
										}
									/>
								</Box>
							)}
							<Box>
								<CommonDatePicker
									sx={{ marginRight: "0px" }}
									timeLineTxt={timeLineTxt}
									setTimeLineTxt={setTimeLineTxt}
									setTimeMilliseconds={setTimeMilliseconds}
									getAnalytics={getAnalytics}
									setIsCustom={setIsCustom}
									reportsPage
								/>
							</Box>
						</Box>
					</TableHeaderWrapper>
					<Box
						sx={{
							flex: 1,
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							width: "100%",
							height: "100%",
							overflow: "hidden",
						}}
					>
						{connectionReportsLoading || reports === null ? (
							<StyledLoaderBoxContain height="75vh">
								<CircularProgress
									variant="indeterminate"
									disableShrink
									size={45}
									thickness={8}
									style={{ color: color.loadingColor }}
								></CircularProgress>
							</StyledLoaderBoxContain>
						) : reports?.length === 0 ? (
							<Box
								sx={{
									margin: 2,
									padding: "20px",
									textAlign: "center",
									flex: 1,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<TableCellTypography>No reports found</TableCellTypography>
							</Box>
						) : (
							<TableContainer sx={{ overflowY: "auto" }}>
								<Table>
									<StickyTableHead>
										<TableRow>
											{connectionReportsHeaders.map((header) => (
												<StyledTableCell key={header.id}>
													<TableHeaderTypography>
														{header.name}
													</TableHeaderTypography>
												</StyledTableCell>
											))}
										</TableRow>
									</StickyTableHead>
									<TableBody>
										{reports.map((row) => (
											<Fragment key={row.connectionId}>
												<TableRow
													sx={{
														padding: "15px 15px 0 15px",
														borderRadius: "15px 0 0 15px",
														background:
															expandedRow === row.connectionId
																? "#F1F7FF"
																: "#FFFFFF",
													}}
												>
													<StyledTableCell>
														<img
															src={expandMoreIcon}
															style={{
																cursor: "pointer",
																transform:
																	expandedRow === row.connectionId &&
																	"rotate(180deg)",
																transition: "transform",
															}}
															onClick={() =>
																handleExpandBtnClick(row.connectionId)
															}
															width={16}
															height={16}
															alt="expand"
														/>
													</StyledTableCell>
													<StyledTableCell>
														<TableCellTypography>
															{row.connectionName}
														</TableCellTypography>
														<Typography
															sx={{
																fontSize: "14px",
																fontWeight: 400,
																color: "#929396",
															}}
														>
															{row.connectionId}
														</Typography>
													</StyledTableCell>
													<StyledTableCell>
														<TableCellTypography>
															{row.sourceConnectorName}
														</TableCellTypography>
													</StyledTableCell>
													<StyledTableCell>
														<TableCellTypography>
															{row.destinationConnectorName}
														</TableCellTypography>
													</StyledTableCell>
													<StyledTableCell>
														<TableCellTypography>
															{getStatusChip(row.status)}
														</TableCellTypography>
													</StyledTableCell>
													<StyledTableCell>
														<TableCellTypography>
															{formatTimestamp(row.firstActivated)}
														</TableCellTypography>
													</StyledTableCell>
													<StyledTableCell>
														<TableCellTypography>
															<Tooltip
																title={formatWithCommas(row.totalVolume)}
																placement="bottom"
															>
																{formatRows(row.totalVolume)}
															</Tooltip>
														</TableCellTypography>
													</StyledTableCell>
													<StyledTableCell>
														<Box sx={{ textAlign: "center" }}>
															<img
																src={downloadIcon}
																style={{
																	cursor: "pointer",
																}}
																onClick={() =>
																	_downloadReport(row.connectionId)
																}
																width={16}
																height={16}
																alt="download"
															/>
														</Box>
													</StyledTableCell>
												</TableRow>
												<TableRow
													sx={{
														padding: "0 15px 15px 15px",
														borderRadius: "0 15px 15px 0",
														background:
															expandedRow === row.connectionId
																? "#F1F7FF"
																: "#FFFFFF",
													}}
												>
													<StyledTableCell colSpan={8} style={{ padding: 0 }}>
														<Collapse
															in={expandedRow === row.connectionId}
															timeout="auto"
															unmountOnExit
														>
															{expandedRow ? (
																reportDataLoading ? (
																	<StyledLoaderBoxContain height="4rem">
																		<CircularProgress
																			variant="indeterminate"
																			disableShrink
																			size={45}
																			thickness={8}
																			style={{ color: color.loadingColor }}
																		></CircularProgress>
																	</StyledLoaderBoxContain>
																) : reportData?.response ? (
																	reportData.response.connectionReportData
																		.length === 0 ? (
																		<Box
																			sx={{
																				margin: 2,
																				padding: "20px",
																				textAlign: "center",
																			}}
																		>
																			<TableCellTypography>
																				No report data found
																			</TableCellTypography>
																		</Box>
																	) : (
																		<>
																			<Box
																				sx={{
																					margin: 2,
																					padding: "20px",
																					borderRadius: "5px",
																					background: "#FFFFFF",
																				}}
																			>
																				<Table size="small">
																					<TableHead>
																						<TableRow>
																							{connectionReportDataHeaders.map(
																								(header) => (
																									<StyledTableCell
																										key={header.id}
																									>
																										<TableHeaderTypography
																											nestedHeader
																										>
																											{header.name}
																										</TableHeaderTypography>
																									</StyledTableCell>
																								)
																							)}
																						</TableRow>
																					</TableHead>
																					<TableBody>
																						{reportData.response.connectionReportData
																							.slice(0, 10)
																							.map((row) => (
																								<ReportDataRow row={row} />
																							))}
																					</TableBody>
																				</Table>
																			</Box>
																			{reportData.response.connectionReportData
																				.length > 10 &&
																				expandedRow === row.connectionId && (
																					<Typography
																						sx={{
																							textDecoration: "underline",
																							color: "#538BF4",
																							fontSize: "16px",
																							fontWeight: "600",
																							margin: "0 auto 16px auto",
																							cursor: "pointer",
																							width: "fit-content",
																						}}
																						onClick={() => {
																							setSelectedConnection(row);
																							setExpandedRow(false);
																						}}
																					>
																						View More
																					</Typography>
																				)}
																		</>
																	)
																) : (
																	<Box
																		sx={{
																			margin: 2,
																			padding: "20px",
																			textAlign: "center",
																		}}
																	>
																		<TableCellTypography>
																			Unable to fetch report data
																		</TableCellTypography>
																	</Box>
																)
															) : null}
														</Collapse>
													</StyledTableCell>
												</TableRow>
											</Fragment>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Box>
				</TableWrapper>
			)}
		</AppLayout>
	);
};

export default Reports;
