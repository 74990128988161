import {
	Box,
	CircularProgress,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
} from "@mui/material";
import { Fragment, useEffect } from "react";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import {
	StickyTableHead,
	StyledTableCell,
	TableHeaderTypography,
	TableWrapper,
} from "./index.styles";
import { StyledLoaderBoxContain } from "../../components/AppLayout/AppLayout.styles";
import { color } from "../../utils/Colors";
import ReportDataRow from "./ReportDataRow";

const ReportConnections = ({
	loading,
	data,
	selectedConnection,
	setSelectedConnection,
	connectionReportDataHeaders,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Fragment>
			<Box sx={{ margin: "20px 20px 0px 20px" }}>
				<SBreadCrumb
					mainPathName="Reports"
					mainPath="reports"
					pathName={selectedConnection.connectionName}
					secondaryPathName={selectedConnection.connectionId}
					onMainPathNameClick={() => setSelectedConnection(null)}
				/>
			</Box>
			<TableWrapper>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						width: "100%",
						height: "100%",
					}}
				>
					{loading ? (
						<StyledLoaderBoxContain height="75vh">
							<CircularProgress
								variant="indeterminate"
								disableShrink
								size={45}
								thickness={8}
								style={{ color: color.loadingColor }}
							></CircularProgress>
						</StyledLoaderBoxContain>
					) : (
						<TableContainer sx={{ overflowY: "auto" }}>
							<Table>
								<StickyTableHead>
									<TableRow>
										{connectionReportDataHeaders.map((header) => (
											<StyledTableCell key={header.id}>
												<TableHeaderTypography nestedHeader>
													{header.name}
												</TableHeaderTypography>
											</StyledTableCell>
										))}
									</TableRow>
								</StickyTableHead>
								<TableBody>
									{data.response.connectionReportData.map((row) => (
										<ReportDataRow row={row} />
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>
			</TableWrapper>
		</Fragment>
	);
};

export default ReportConnections;
