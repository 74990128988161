import React, { useEffect, useMemo, useState } from 'react'
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Fade, Typography, Modal, InputLabel, Skeleton } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'


import InfoIcon from '../../assets/NewInfo.svg'
import GraphContent from './GraphContent';
import TablePricing from './TablePricing';
import AppLayout from '../../components/AppLayout/AppLayout'
import { madTechState } from '../../features/madTechSlice'
import { useGetPricingPlanMutation } from '../../services/query'
import TextNormalFields from './TextNormalFields'
import InfoModal from './infoModal'
import { CurrentPlanWrapper } from './index.styles';
import SBreadCrumb from '../../components/Setting/SBreadCrumb';

const Pricing = () => {
    const style = React.useMemo(
        () => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "8px 8px 8px 8px",
            bgcolor: "#F8F8FE",
            width: "31.25rem",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&:focus": {
                outline: "none",
            },
        }),
        []
    );
    const { selectedAccount, user } = useSelector(madTechState);

    const [defaultAnnualformdata, setDefaultAnnualFormdata] = React.useState({});
    const [formdata, setFormdata] = React.useState({});
    const [planDropdown, setPlanDropdown] = useState([])
    const [infoOpen, setInfoOpen] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('accountId')

    const [defaultPlanCpcRate, setDefaultPlanCpcRate] = useState([])
    // For Testing
    // const data = IndexData;
    const [getPricing, { data , isLoading, error }] = useGetPricingPlanMutation();//data,

    useEffect(() => {
        getPricing({ id })
    }, [])

    const convertSpendValuesToArrayOfObjects = (spendValues, tierStart) => {
        return spendValues.map((spendValue, index) => {
            return {
                tier: tierStart + index,
                spendValue: spendValue
            };
        });
    };

    useEffect(() => {
        if (data !== undefined) {
            if (data.statusCode === 200) {
                const currentData = data?.response?.currentPlan
                const updatedDefaultPlan = data?.response?.defaultPlan;
                const defaultAnnualdatas = {
                    ...updatedDefaultPlan[1],
                    cpcRate: updatedDefaultPlan[1]?.cpcRate?.tier
                };
                setDefaultAnnualFormdata(currentData?.plan === "annual" ? currentData : defaultAnnualdatas)
                setFormdata({
                    plan: currentData?.plan,
                    cpmrDiscount: currentData?.plan === "Monthly" ? defaultAnnualdatas.cpmrDiscount : currentData?.cpmrDiscount,
                    minMonthlySpend: currentData?.minMonthlySpend,
                    annualSpend: currentData?.annualSpend,
                    renewalDate: currentData?.renewalDate,
                    cpcRate: currentData?.cpcRate?.tier
                })

                setCurrentPlan(currentData?.plan === null ? updatedDefaultPlan[0]?.plan?.toLocaleLowerCase() : currentData?.plan?.toLocaleLowerCase())
                const Plan = convertSpendValuesToArrayOfObjects(updatedDefaultPlan[1]?.cpcRate?.spendValues, 1);
                setDefaultPlanCpcRate(Plan);
                const newArray = updatedDefaultPlan?.map(item => {
                    return {
                        plan: item?.plan?.toLocaleLowerCase(),
                        planName: item?.planName
                    };
                });
                setPlanDropdown(newArray)

            }

        }
    }, [data, isLoading])

    const methods = useForm()
    const { register, handleSubmit, watch, setValue, reset } = useForm();
    const [currentPlan, setCurrentPlan] = React.useState("");
    const [editMode, setEditMode] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const annualSpend = watch("annualSpend");
    const discount = watch("cpmrDiscount");
    const SelectedTier = watch("cpcRate");
    useEffect(() => {
        if (annualSpend) {
            const spend = annualSpend > 0 ? annualSpend / 12 : 0

            setValue("minMonthlySpend", spend.toFixed(2).toLocaleString());
        }
        if (discount !== undefined && discount !== null) {
            let newValue = parseFloat(discount);
            if (newValue < 0) {
                newValue = 0;
            } else if (newValue > 25) {
                newValue = 25;
            }
            newValue = Math.round(newValue * 100) / 100;
            setValue("cpmrDiscount", newValue);
        }
    }, [annualSpend, discount, setValue]);
    const handleClose = () => {
        setOpen(false);
    };

    const matchingPlanName = useMemo(() => {
        return planDropdown?.find(item => item.plan === currentPlan)?.planName;
    }, [currentPlan, planDropdown]);


    return (
        <AppLayout>
            <Box px="1.5rem" >
                {/* Heading */}
                <Box display="flex" >
                    <SBreadCrumb mainPathName="Settings" mainPath="myprofile" pathName="Pricing" />
                </Box>
                {/* Current Plan */}
                <CurrentPlanWrapper >
                    <form>
                        <Box className="pricing-form-select">
                            <Box display= "flex" >
                                <label className='select-label'>Current Plan :</label>

                                <>{isLoading ? <Skeleton variant="rounded" width={210} height={37} /> : <label className='select-value'>{matchingPlanName}</label>}</>
                            </Box>
                            <Box className="Icon-main-contents">
                                <Box className="Icon-main-contents">
                                    <img className='img-style' alt="" src={InfoIcon} onClick={() => setInfoOpen(true)}></img>
                                </Box>

                            </Box>
                        </Box>
                        {currentPlan === "annual" && (<Box sx={{ display: currentPlan === "monthly" || editMode ? "none" : "flex", alignItems: "center", mt: 1, gap: "20px" }}>
                            <TextNormalFields currentPlan={currentPlan} lable="Annual Spend" StartPrefix="$" isLoading={isLoading} value={(formdata.annualSpend ? Number(formdata.annualSpend) : 0).toLocaleString()} />
                            <TextNormalFields currentPlan={currentPlan} lable="Renewal Date" isLoading={isLoading} value={formdata.renewalDate ? dayjs(Number((formdata.renewalDate) * 1000)).format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY')} />
                            <TextNormalFields currentPlan={currentPlan} lable="Min. Monthly Spend" isLoading={isLoading} StartPrefix="$" value={(formdata.minMonthlySpend ? Number(formdata.minMonthlySpend) : 0).toLocaleString()} />
                            <TextNormalFields currentPlan={currentPlan} lable="Base Tier" isLoading={isLoading}
                                value={<div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span>Tier{defaultPlanCpcRate?.find(item => item.tier === formdata.cpcRate)?.tier}</span>
                                    <span style={{ marginRight: "16px" }}> ${defaultPlanCpcRate?.find(item => item.tier === formdata.cpcRate)?.spendValue}</span>
                                </div>} />
                            {/* <TextNormalFields  currentPlan={currentPlan} lable="CPMR Discount" isLoading={isLoading} EndPrefix="%" value={formdata.cpmrDiscount ? formdata.cpmrDiscount : 0} /> */}
                        </Box>)}
                    </form>
                </CurrentPlanWrapper>


                {/* Graph Content */}
                {/* <Box
                    sx={{
                        backgroundColor: "#fff",
                        p: "25px",
                        boxShadow:
                            "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
                        borderRadius: 2,
                    }}
                >
                    <GraphContent target={formdata?.minMonthlySpend} paramsId={id} />
                </Box> */}


                {/* Table  */}
                <TablePricing setFormdata={setFormdata} formdata={formdata} isLoading={isLoading} currentPlan={formdata?.plan} paramsId={id} name={undefined} />
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={{ ...style, pl: 5, pr: 5, width: 500 }}>
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontFamily: "Helvetica Neue",
                                fontSize: "1rem",
                                textAlign: "center",
                            }}
                        >
                        </Typography>

                        <Typography
                            style={{
                                fontWeight: 400,
                                fontFamily: "Helvetica Neue",
                                fontSize: "1rem",
                                textAlign: "center",
                                color: "#656C7B",
                                width: "70%",
                                marginBottom: 16,
                            }}
                        >
                            Proceed with changing the pricing structure?
                        </Typography>
                        <form >
                            <Box
                                sx={{
                                    width: "100%",
                                    mt: 3,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "1rem",
                                }}
                            >
                                <Button
                                    type='submit'
                                    sx={{
                                        cursor: "pointer",
                                        // backgroundColor: Theme.buttonColor,
                                        width: "106px",
                                        height: "40px",
                                        marginRight: "0.625rem",
                                        // color: Theme.whiteColor,
                                        textTransform: "none",
                                        backgroundColor: "#000",
                                        '&:hover': {
                                            backgroundColor: '#000',
                                        },
                                    }}
                                    variant="contained"
                                >
                                    <Typography
                                        style={{
                                            fontSize: "1rem",
                                            fontWeight: 500,
                                            fontFamily: "Helvetica Neue",
                                            marginTop: 2,

                                        }}
                                    >
                                        Yes
                                    </Typography>
                                </Button>

                                <Button
                                    onClick={handleClose}
                                    style={{
                                        cursor: "pointer",
                                        // backgroundColor: Theme.ButtonSecondryColor,
                                        width: "106px",
                                        height: "40px",
                                        // color: Theme.themeBlack,
                                        textTransform: "none",
                                    }}
                                    variant="contained"
                                >
                                    <Box sx={{ display: "flex", gap: "0.5rem" }}>
                                        <Typography
                                            style={{
                                                fontSize: "1rem",
                                                fontWeight: 500,
                                                fontFamily: "Helvetica Neue",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            No
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Fade>
            </Modal>
            <InfoModal setInfoOpen={setInfoOpen} infoOpen={infoOpen} formdata={formdata} save />
        </AppLayout>
    )
}

export default Pricing