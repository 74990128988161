const SmallDot = ({ color }) => {
	return (
		<svg
			width="6"
			height="5"
			viewBox="0 0 6 5"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="3.08984" cy="2.5" r="2.23242" fill={color} />
		</svg>
	);
};

export default SmallDot;
